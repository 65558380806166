import React from 'react'
import { PreloadContext } from '../contexts'

class ImagePreload extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.images = this.props.images || ImagePreload.getPreloadArrayFromAnims( this.props.anims, this.props.remoteDefault )
        this.imageRefs = []
        this.totalLoaded = 0
        this.isWired = false
    }

    componentDidMount() {
        this.setPreloaded = this.context && this.context.setPreloaded
    }

    componentDidUpdate() {
        if(!this.context || this.context.preload)
            this.addLoadListeners()
    }

    addLoadListeners() {
        if(this.isWired)
            return
        this.imageRefs.map(img=>{
            if(img.complete)
            {
                this.imageLoaded(img)
            }
            else
                img.addEventListener('load', ()=>{
                    this.imageLoaded(img)
                })
        })
        this.isWired = true
    }

    imageLoaded(img) {
        this.totalLoaded++
        if(this.props.onProgress)
            this.props.onProgress(this.totalLoaded / this.imageRefs.length)
        if(this.totalLoaded >= this.imageRefs.length)
        {
            this.setState({loaded: true})
            if(this.setPreloaded)
            {
                // console.log('ImagePreload: imageLoaded: ', this.props.name)
                this.setPreloaded(this.context.pageIdx)
            }
        }
    }

    static getPreloadArrayFromAnims(anims) {
        let preloadArray = []
        anims.map(anim=>{
            anim.anims.map(a=>{
                let path
                if(a.p || a.v || a.a)
                {
                    if(a.a || a.v)
                        return
                    else if(a.p)
                        path = ImagePreload.getFileUri(a.p)
                }
                preloadArray.push(path)
            })
        })
        return preloadArray
    }

    static getFileUri(src, remoteDefault) {
        let path = ''
        if( src.includes('/') )
            path += `${src}`
        else if(remoteDefault)
            path += `${remoteDefault}${src}`
        else
            path += `/images/siteV2/${src}`
        if( !src.includes('.') )
            path += `.jpg`
        return path
    }

    render() {
        return (
            <>
                {(!this.context || this.context.preload) ?
                    <div style={{height:0, overflow:'hidden'}}>
                        {this.images.map((i,k)=>{
                            if(!i)
                                return
                            let uri = ImagePreload.getFileUri(i, this.props.remoteDefault)
                            // console.log(uri)
                            return (
                                <img src={ uri } key={k} ref={r=>{
                                    if(!r)
                                        return
                                    if( !this.imageRefs.find(i=>i.src==r.src) )
                                        this.imageRefs.push(r)
                                }}/>
                            )
                        })}
                    </div>
                :
                    <div />
                }
            </>
        )
    }
}
ImagePreload.contextType = PreloadContext
   
export default ImagePreload