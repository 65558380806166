import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class Segmentation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(function(){
                turnIn( $('#monitorSegmentation') )
            },400)
        }
    }

    render() {
        return (
            <Card handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-segmentation">
                <div>
                    <h4>Tailored to Your Business</h4>
                    <div>Every aspect of the Refresh platform is built around a segmentation structure that is configured to your organization. Target employees based on locations, groups, teams, and roles. Want to offer Acupuncture to your Facilities team in Oklahoma City? Send a 4th of July bonus message to Sales in Seattle? It’s all possible with Refresh.</div>
                </div>
                <Columns>
                    <Card>
                        {/* <div id="segmentationAnim" class="graphic animation" /> */}
                        <div style={{textAlign:'center'}}>
                            <img id="segmentationStatic" src="/images/siteV2/segmentation-static.png" />
                        </div>
                    </Card>
                    <Card>
                        <div class="threedee-frame square ratio">
                            <div
                                id="monitorSegmentation"
                                class="mockup monitor will-turn-in"
                                content="/images/siteV2/m-segmentation-desktop.jpg"
                            ></div>
                        </div>
                    </Card>
                </Columns>
                <ImagePreload name="segmentation" images={['segmentation-static.png', 'm-segmentation-desktop.jpg']} />
            </Card>
        )
    }
}
Segmentation.contextType = PreloadContext
   
export default Segmentation