import React from 'react'

import Header from './Header'
import DemoBox from './landings/DemoBox'
import PageCounter from '../PageCounter'
import ImagePreload from '../views/ImagePreload'
import { PreloadContext } from '../contexts'

class Pages extends React.Component {
    
    constructor(props) {
        super(props)
        this.submenu = []
        this.state = {
            preloads: {},
            preloadeds: {}
        }
        let counter = new PageCounter(1)
        this.childArray = React.Children.toArray(this.props.children)
        this.childArray.map((child, idx)=>{
                let pageIdx = idx+1
                this.state.preloads[pageIdx] = false
                this.state.preloadeds[pageIdx] = false
            })
        global._openDemo = () => this.openDemo()
    }

    componentDidMount() {
        preBuild(this.props._opts)
        subMenuStart = 1
        $(function(){
            initScroll( window.destTriggers )
            postBuild()
        })
    }

    componentDidUpdate() {
        if(this.state.pagesPreloaded && !this.didInit)
        {
            console.log('Pages componentDidUpdate: this.state.pagesPreloaded ', this.state.pagesPreloaded)
            this.didInit = true
            renderMockups()
            calcSizes()
            setTimeout(()=>this.setState({ pagesRendered: true }), 0)
        }
    }

    addToMenu = (item) => {
        this.submenu.push(item)
        this.setState({ submenu: this.submenu })
    }

    loadNextSibling = pageIdx => {
        let preloads = Object.assign({}, this.state.preloads)
        preloads[pageIdx] = true
        preloads[pageIdx+1] = true
        console.log('loadNextSibling', pageIdx+1)
        this.setState({ preloads })
    }

    setPreloaded = pageIdx => {
        let preloadeds = Object.assign({}, this.state.preloadeds)
        preloadeds[pageIdx] = true
        console.log('setPreloaded', pageIdx)
        this.setState({ preloadeds })
    }

    openDemo = () => {
        this.setState({ demoOpen: true })
        if(pageView)
            pageView(null, '#demoRequestPopupOpen')
    }

    closeDemo = () => {
        this.setState({ demoOpen: false })
        if(pageView)
            pageView(null, '#demoRequestPopupClose')
    }

    render() {
        console.log('Pages: re-render')
        let opts = this.props._opts || {}
        let counter = new PageCounter(1)
        let children = React.Children.toArray(this.props.children)
            .map((child, idx)=>{
                let pageIdx = idx+1
                let el = React.cloneElement( child, {
                    idx: counter.next(),
                    isLast: idx==this.childArray.length-1,
                    addToMenuFn: this.addToMenu,
                    // docFunctions: this.props.docFunctions,
                    docFunctions: {
                        openDemo: this.openDemo
                    },
                    // preload: this.state.preloads[pageIdx],
                    loadNextFn: this.loadNextSibling
                } )
                return (
                    <PreloadContext.Provider value={{
                        pageIdx,
                        preload: this.state.preloads[pageIdx],
                        preloaded: (this.state.pagesRendered && this.state.preloadeds[pageIdx]),
                        setPreloaded: this.setPreloaded
                    }} key={pageIdx}>
                        {el}
                    </PreloadContext.Provider>
                )
            })
        return (
            <>
                <Header
                    openDemo={this.openDemo}
                    wide={opts.newLanding}
                    pagesMenu={_isInternal || this.props.pagesMenu}
                    burgerMenu={this.props.burgerMenu}
                />
                { (this.props.submenu && this.state.submenu) &&
                    <SubMenu items={this.state.submenu} class="hide-mobile" />
                }
                <div id="pageBlocks">
                    {children}
                </div>
                <DemoBox open={this.props.demoOpen || this.state.demoOpen} closeFn={this.closeDemo} />
                <PreloadContext.Provider value={{
                    preload: true,
                    setPreloaded: ()=>{ this.setState({pagesPreloaded:true}) }
                }}>
                    <ImagePreload name="main" images={[
                        'iphonex-blue.png',
                        'iphonex-blue-noshadow.png',
                        'imac.png',
                        'mockup-init.jpg',
                        'landing-dialog-side-bg.svg'
                    ]} />
                </PreloadContext.Provider>
                
            </>
        )
    }
}

class SubMenu extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        console.log('SubMenu: re-render')
        let p = this.props

        let categories = {}
        p.items.map(item=>{
            if(!categories[item.category])
                categories[item.category] = []
            categories[item.category].push(item)
        })

        return (
            <div class={`sub-header`}>
                <div class="sub-menu stretch-wide">
                    { Object.keys(categories).map((k,idx)=>(
                        <React.Fragment key={idx}><div class={`sub-menu-section ${k}`}>
                            { categories[k].map((item, idx)=>(
                                <React.Fragment key={idx}><SubMenuItem href={item.id}>{item.title}</SubMenuItem> </React.Fragment>
                            ))}
                        </div> </React.Fragment>
                    ))}
                </div>
            </div>
        )
    }

}

function SubMenuItem(p) {
    // return <a class="sub-menu-item" href={p.href} icon="●">{p.children}</a>
    return  <span>
				<a class="sub-menu-item" href={p.href} icon="●">
                    <span class="sub-menu-item-icon" />
                    <span class="sub-menu-item-title">{p.children}</span>
                </a>
			</span>
}

function recursiveMap(children, parent, fn) {
    return React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
            return child
        }

        if (child.props.children) {
            child = React.cloneElement(child, {
                children: recursiveMap(child.props.children, child, fn),

            })
        }

        return fn(child, parent)
    })
}
Pages.contextType = PreloadContext

export default Pages