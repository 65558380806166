import React from 'react'
import LandingPages from '../LandingPages'
import Hero from './pages/Hero'
import HeroTwo from './pages/HeroTwo'
import Challenges from './pages/Challenges'
import InsuranceAndReimbursement from './pages/InsuranceAndReimbursement'
import FormsAndSurveys from './pages/FormsAndSurveys'
import Rewards from './pages/Rewards'
import Messaging from './pages/Messaging'
import Scheduling from './pages/Scheduling'
import RequestDemo from './pages/RequestDemo'

var USER = 'user',
    DEMO = 'demo'

class Onboarding extends React.Component {
	constructor(props) {
		super(props)
    }

    render() {

        // let blurb = <>
        //     <p>As the business world returns from the Covid-19 your workforce will be expecting changes that both keep them safe and allow for a more flexible approach to work. A robust technology-based solution is the most effective way to manage the return to the office.</p>
        //     <p>Daily polling allows you to keep track of how employees are feeling over time, scheduling tools allow employees to plan office versus remote working, communication and content tools to keep employees engaged with your company’s culture and core values.</p>
        // </>

        let blurb = <>
            <p>Refresh is a robust solution that effectively manages a return to the office.</p>
            <p>Daily polling allows you to keep track of how employees are feeling over time, scheduling tools allow employees to plan office versus remote working, and communication and content tools to keep employees engaged with your company’s culture and core values.</p>
        </>

        return (
            <LandingPages submenu={true}>
                <Hero
                    title="Come back, safely!"
                    subtitle="Managing The New Normal"
                    blurb={blurb} />
                <HeroTwo
                    title={blurb}
                    nextText="Forms + Surveys" />
                <FormsAndSurveys
                    inSubmenu={USER}
                    nextText="Impressive Scheduling" />
                <Scheduling
                    inSubmenu={USER}
                    nextText="Universal Messaging" />
                <Messaging
                    inSubmenu={USER}
                    nextText="Insurance + Reimbursement" />
                <InsuranceAndReimbursement
                    inSubmenu={USER}
                    nextText="Employee Recognition + Challenges" />
                <Challenges
                    inSubmenu={USER}
                    nextText="Get a free demo" />

                <RequestDemo
                    inSubmenu={DEMO} />

            </LandingPages>
        )
    }
}

export default Onboarding