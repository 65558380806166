import React from 'react'

import Header from './Header'
import DemoBox from './landings/DemoBox'
import blogUtils from '../blogUtils'

class Blog extends React.Component {
	constructor(props) {
		super(props);
        this.state = { images: {} }
    }

    componentDidMount() {
        $('body').append( $('<style>.v2 { height: auto; }</style>') )

        let load = () => {
            let slug
            if(window.location.href.includes('localhost'))
                slug = window.location.hash.replace('#', '')
            else
            {
                let parts = window.location.pathname.split('/')
                slug = parts[parts.length-1]
            }
            slug = slug.split('___')[1]
            this.loadPage({
                slug,
                url: `https://docs.google.com/document/u/1/d/e/${slug}/pub`
            })
        }
        load()
        // setInterval(load, 5*1000)

        addAllStyles()
        postBuild()
    }

    loadPage({slug, url}) {
        $.get(url, content=>this.scrapeContent(content))
        console.log('loadPage')
    }

    scrapeContent(raw) {
        let jFrame = $('.main-content-frame')
        jFrame.empty()
        let jRoot = $(raw),
            jContent = $(jRoot[5]),
            title = jContent.find('.title').text(),
            images = {}
        jContent.find('style, .title').remove()
        blogUtils.changeTag(jContent, 'h3', 'h4')
        blogUtils.changeTag(jContent, 'h1', 'h3')
        blogUtils.clearClasses(jContent, ['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10'])
        jContent.find('img').each((i, el)=>{
            let jImg = $(el),
                jWrap = jImg.parent()
            jWrap.replaceWith(jImg)
            if(i==0)
            {
                images.hero = jImg.attr('src')
                jImg.remove()
            }

        })
        this.setState({ title, images })
        jFrame.prepend(jContent)
    }

    openDemo = () => {
        this.setState({ demoOpen: true })
        if(pageView)
            pageView(null, '#demoRequestPopupOpen')
    }

    closeDemo = () => {
        this.setState({ demoOpen: false })
        if(pageView)
            pageView(null, '#demoRequestPopupClose')
    }

    render() {
        let { title, images } = this.state
        return (
            <>
                <Header
                    openDemo={this.openDemo}
                    // wide={true}
                    pagesMenu={true}
                    // burgerMenu={this.props.burgerMenu}
                />
                <div class="main-header-size" />
                <div class="blog">

                    <div class="blog-hero">
                        <div class="bg"
                            style={{backgroundImage: `url(${images.hero})` }}
                        />
                        <div class="content">
                            <h5 class="blog-title">Knowledge Base</h5>
                            <h1 class="title">{title}</h1>
                        </div>
                    </div>

                    <div class="content main-content-frame">
                        {/* Content gets placed here */}
                    </div>
                </div>

                <DemoBox open={this.state.demoOpen} closeFn={this.closeDemo} />
            </>
        )
    }
}

export default Blog