import React from 'react'

var TOP = '?top=1'

class Header extends React.Component {

    constructor(props) {
        super(props)
        let isLocal = window.location.host.includes('localhost')
        let menuItems = [
            // {   title: 'Overview',                      link: isLocal ? '/v2.html' : '/'    },
            {   title: 'Employee Experience',               link: 'https://info.refreshplatform.com/employee-experience'    },
            {   title: 'Insurance Client Experience',       link: 'https://info.refreshplatform.com/insurance_client_experience'    },
            {   title: 'Change Management',                 link: 'https://info.refreshplatform.com/change-management', newLine: true    },
            {   title: 'HR Goals',
                options: [
                    {   title: 'Wellness',                  link: isLocal ? '/v2landing-wellness.html' : '/product/wellness'    },
                    {   title: 'Benefits Discovery',        link: isLocal ? '/v2landing-benefits.html' : '/product/benefits'    },
                    {   title: 'Communication',             link: isLocal ? '/v2landing-comms.html' : '/product/communication'    },
                    {   title: 'Onboarding',                link: isLocal ? '/v2landing-onboarding.html' : '/product/onboarding'    },
                    {   title: 'Employee Recognition',      link: isLocal ? '/v2landing-employee-recognition.html' : '/product/employeerecognition'    },
                    {   title: 'Return To Work',            link: isLocal ? '/v2landing-return-to-work.html' : '/product/returntowork'    },
                ]
            },
            {   title: 'Product Tour',              link: isLocal ? '/v2companies.html' : '/companies'  }
        ]
        menuItems.map(item=>{
            if(item.options)
            {
                item.options.map(option=>{
                    if( window.location.pathname==option.link )
                    {
                        item.active = true
                        item.title += ' → ' + option.title
                    }
                    option.link += TOP
                })
            }
            else if( window.location.pathname==item.link )
            {
                item.active = true
            }
            item.link += TOP
        })
        this.state = {
            menuItems
        }
        this.isLocal = isLocal
    }

    render() {
        return (
            <div class={`main-header ${this.props.wide && 'wide'}`}>
                <div class="content">
                    <a id="homeLink" href={this.isLocal ? '/v2.html' : '/'}>
                        <div class="bg-image" id="headerLogo" ></div>
                    </a>
                    <div class="menu">
                        {this.props.pagesMenu &&
                            <span class="pages-menu">
                                {this.state.menuItems.map((item, idx)=>(
                                    <MenuItem
                                        key={idx}
                                        {...item}
                                    />
                                ))}
                            </span>
                        }
                        <div class="menu-right">
                            <img
                                class="burger"
                                src="/images/siteV2/burger.png"
                                onClick={(event) => {
                                    $('#mainMenu').toggle()
                                    event.stopPropagation()
                                }}
                            />
                            <div class="main-menu" id="mainMenu">
                                <div class="main-menu-bg">
                                    <div class="main-menu-divider"></div>
                                    <a class="main-menu-item" href="#modal_login">Log In</a>
                                    <div class="main-menu-divider"></div>
                                    <a class="main-menu-item" href="/about">About</a>
                                    <a class="main-menu-item" href="/contact-us">Contact</a>
                                </div>
                                <div class="main-menu-blocker"></div>
                            </div>
                        </div>
                        <img class="demo-button" src="/images/siteV2/button-demo.svg" onClick={this.props.openDemo} />
                    </div>
                </div>
            </div>
        )
    }
}

class MenuItem extends React.Component {
    constructor(props) {
        super(props)

        this.wrapperRef = React.createRef()
        this.state = {
            open: false
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClick = item => {
        if(item.options)
            return this.toggle()
        else
		    _navTo(item.link)
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.toggle(false)
        }
    }

    toggle = state => {
        let open = (typeof state != 'undefined') ? state : !this.state.open
        this.setState({ open })
    }

    render() {
        
        let p = this.props
        return (
            <>
                { !!p.newLine && <br/>}
                <a
                    class={`menu-item ${p.active && 'always-selected'} ${p.newLine && 'new-line'}`}
                    href={p.options ? undefined : p.link}
                    onClick={()=>this.handleClick(p)}
                    ref={this.wrapperRef}
                >
                    {p.title}
                    {this.state.open && 
                        <div class="menu-dropdown">
                            {p.options.map((option, idx)=>(
                                <MenuItem
                                    key={idx}
                                    {...option}
                                />
                            ))}
                        </div>
                    }
                </a>
            </>
        )
    }
}

export default Header