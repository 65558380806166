import React from 'react'
import Pages from './Pages'
import Page from './Page'
import PageHeader from './PageHeader'
import MainHero from './pages/MainHero'
import Columns from './Columns'

import DemoVideo from './DemoVideo'
import ModularSolution from './cards/ModularSolution'
import OnEveryPlatform from './cards/OnEveryPlatform'
import Coordination from './cards/Coordination'
import Communication from './cards/Communication'
import Challenges from './cards/Challenges'
import HealthPlan from './cards/HealthPlan'
import Expenses from './cards/Expenses'
import Rewards from './cards/Rewards'
import Segmentation from './cards/Segmentation'
import DashboardAnalytics from './cards/DashboardAnalytics'
import DashboardMessaging from './cards/DashboardMessaging'
import DashboardPolling from './cards/DashboardPolling'
import DashboardExpenses from './cards/DashboardExpenses'

import Wellness from './pages/Wellness'
import RequestDemo from './landings/pages/RequestDemo'
import CallUs from './CallUs'
import ImagePreload from './ImagePreload'

var USER = 'user',
    DASHBOARD = 'dashboard'

class Companies extends React.Component {
	constructor(props) {
		super(props)
        this.state = {}
    }

    componentDidMount() {
		// _wireCompanies()
    }

    render() {

        return (
            <>

                <Pages
                    pagesMenu={true}
                    burgerMenu={true}
                    submenu={true}
                >
                    
                    {!_isInternal &&
                        <MainHero />
                    }
                    
                    <Page id='companies'
                        title='Companies'
                        custom={true}
                        extraAttr={{'next-label': 'What is Refresh?' }}
                        handleAppearOnce={()=>{
                            delayPeekSlidable( $('#intro') )
                        }}
                    >
                        <PageHeader
                            title="The Refresh Platform"
                            subtitle="We combine a top-tier digital app for everyday use with live on-site services to provide end-to-end HR solutions."
                        />
                        <div class="content">
                            <Columns id="intro">
                                <ModularSolution />
                                <OnEveryPlatform showVideoFn={url=>this.setState({showVideo:url})} />
                            </Columns>
                        </div>
                    </Page>

                    <Wellness inSubmenu={USER} />

                    <Page id='coordination'
                        title='Coordination'
                        inSubmenu={USER}
                    >
                        <Coordination />
                    </Page>

                    <Page id='communication'
                        title='Communication'
                        inSubmenu={USER}
                    >
                        <Communication />
                    </Page>

                    <Page id='challenges'
                        title='Challenges'
                        inSubmenu={USER}
                    >
                        <Challenges />
                    </Page>

                    <Page id='discovery'
                        title='Discovery'
                        inSubmenu={USER}
                        subsections={[
                            { title: 'Insurance & Expenses', id:'insurance-and-expenses'},
                            { title: 'Rewards & Perks', id:'rewards-and-perks'}
                        ]}
                    >
                        <Columns>
                            <HealthPlan />
                            <Expenses />
                        </Columns>
                        <Rewards />
                    </Page>

                    <Page id='segmentation'
                        title='Segmentation'
                        inSubmenu={DASHBOARD}
                    >
                        <Segmentation />
                    </Page>

                    <Page id='analytics'
                        title='Analytics'
                        inSubmenu={DASHBOARD}
                    >
                        <DashboardAnalytics />
                    </Page>

                    <Page id='workflows'
                        title='Workflows'
                        inSubmenu={DASHBOARD}
                        subsections={[
                            { title: 'Messaging ', id:'messaging'},
                            { title: 'Modules', id:'modules' }
                        ]}
                    >
                        <DashboardMessaging />
                        <Columns>
                            <DashboardPolling />
                            <DashboardExpenses />
                        </Columns>
                    </Page>

                    <RequestDemo />

                    { this.state.showVideo &&
                        <DemoVideo videoSrc={this.state.showVideo} closeFn={()=>this.setState({showVideo:null})} />
                    }

                </Pages>

                <CallUs />

                <ImagePreload
                    images={ [].concat.apply([], servicesImages) }
                    remoteDefault="https://refresh.app.refreshplatform.com/img/events/"
                    preload={this.props.preload}
                />
                
            </>
        )
    }
}

function _wireCompanies() {
	subMenuStart = 2
	$(function(){

		if(!isLanding)
		{
			subMenuStart -= 1
			// setTimeout(()=>{
			// 	loadBodyMovin({id: 'modular', file: 'modular.json', loop: true})
			// 	pagesVisited[1].main = true
			// }, 100)
		}

		initScroll( window.destTriggers )
		postBuild()

	})
}

export default Companies