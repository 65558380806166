import React from 'react'
import LandingPage from '../../LandingPage'
import Columns from '../../Columns'
import LiveServices from '../../cards/LiveServices'
import River from '../../River'
import AppPlatform from '../../cards/AppPlatform'
import VideoSeries from '../../cards/VideoSeries'
import NutritionTools from '../../cards/NutritionTools'

function Wellness(p) {
    return (
        <LandingPage id='wellness-amenities'
            title='Wellness'
            subsections={[
                { title: 'Amenities ', id:'amenities', river:'animServicesRiver'},
                { title: 'Education', id:'education' }
            ]}
            handleAppearOnce={()=>{
                servicesAnimPaused = false
                delayPeekSlidable( $('#wellness-slider') )
                setTimeout(initServicesAnim,0)
            }}
            classes={{
                // 'wires-bg': false,
                // 'dark': true
            }}
            {...p}
        >
            {/* <River id="animServicesRiver" /> */}
            <Columns
                id="wellness-slider"
            >
                <VideoSeries
                    title={<h2>Live + Digital Sessions</h2>}
                    body={
                        <>
                            Tap into <b>100+ Refresh Live™ practitioners</b> broadcasting daily across a variety of programming (<b>yoga, ergonomics, stretching, fitness, nutrition</b>), or cast your own, to deliver both <b>group and individual video sessions</b> seamlessly to your employees.
                        </>
                    }
                    {...p}
                />
                <AppPlatform
                    title={<h2>...delivered directly</h2>}
                    body={<><b>Segment by geographies or populations</b>; leverage features like <b>event signups</b>, <b>calendar holds</b>, <b>check-in</b>, <b>payment processing</b> and <b>analytics & tracking</b> - all embedded and easy to use.</>}
                    {...p}
                />
            </Columns>
        </LandingPage>
    )
}
   
export default Wellness