import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

let anims1 = {

    target: '#phoneSocial',
    wait: 2,
    anims: [
        {
            t:0,
            p:'m-kudos-form'
        },
        {
            t:2,
            p:'m-kudos-people'
        }
    ]
}

class SocialRecognition extends React.Component {

    constructor(props) {
		super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(function(){
                turnIn( $('#phoneSocial') )
                runAnim(anims1, 3)
                delayPeekSlidable( $('#social-slider') )
            },400)
        }
    }

    render() {
        return (
            <>
            <Columns handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-social" id="social-slider">
                <Card>
                    {this.props.title ||
                        <h4>Social Recognition Platform</h4>
                    }
                    {this.props.body ||
                        <div>
                            <p>Our kudos system provides your workforce with an <b>easy way to provide recognition</b> for hard work and a job well done.</p>

                            <p>Employees can <b>send a note of thanks</b> any time they want. These can <b>queue with a manager</b> or get <b>delivered in real-time</b>. </p>

                            <p>Managers can preset questions for employees to answer about their colleagues to <b>facilitate 360° reviews</b>.</p>
                        </div>
                    }
                    {/* <div id="challengesAnim" class="graphic animation" /> */}
                    <div style={{textAlign:'center'}}>
                        <img id="socialStatic" src="/images/siteV2/social-recognition.png" />
                    </div>
                </Card>
                <Card>
                    <div class="threedee-frame square ratio">
                        <div
                            id="phoneSocial"
                            class="mockup phone will-turn-in expandable"
                            content="/images/siteV2/mockup-init.jpg"
                        ></div>
                    </div>
                </Card>
            </Columns>
            <ImagePreload name="social" anims={[anims1]} />
            </>
        )
    }
}
SocialRecognition.contextType = PreloadContext
   
export default SocialRecognition