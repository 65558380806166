import React from 'react'
import {
    PageContext,
    SectionContext
} from '../contexts'
import PageHeader from './PageHeader'
import { StyleContext } from '../contexts'
import registerTriggers from '../Triggers'

class Page extends React.Component {

	constructor(props) {
		super(props)
        this.state = {}

        if(this.props.inSubmenu && this.props.addToMenuFn)
            this.props.addToMenuFn({
                id: this.props.id,
                title: this.props.title,
                category: this.props.inSubmenu
            })
        this.pageId = this.props.idx
    }

    componentDidMount() {
        registerTriggers(this)
    }

    handleAppearOnce = () => {
        if(this.props.loadNextFn)
            this.props.loadNextFn(this.pageId)
    }

    openDemo = () => {
        if(this.props.docFunctions && this.props.docFunctions.openDemo)
            this.props.docFunctions.openDemo()
    }

    render() {
        let p = this.props
        let classes = Object.assign({
                'page-block': true,
                'bulleted': true,
                'align-top': this.context.pagesAlignTop,
                'wires-bg': true,
                'with-sub-menu': true
            }, p.classes)
        let classString = ''
        for(var c in classes)
            if(classes[c])
                classString += c + ' '
        classString += ` page-${p.id}`

        let header = (
            <PageHeader
                title={p.title}
                subsections={p.subsections}
            />
        )

        return (
            <PageContext.Provider value={{ pageId: this.props.idx }}>
                <div
                    id={`${p.id}Page`}
                    data-anchor={p.id}
                    className={classString}
                    {...p.extraAttr}
                    key={p.key}
                    ref={r=>this.page=r}
                >
                    <div className="page-block-upper">
                        { p.custom ?
                            p.children
                        :
                            p.subsections ?
                                <div id={`${p.id}Subsections`} class="subsections">
                                    { header }
                                    <div class="content">
                                        { p.subsections.map((subsection, idx)=>(
                                            <SubSection {...subsection} key={idx}>
                                                <SectionContext.Provider value={{ sectionId: idx }}>
                                                    { React.Children.toArray(p.children)[idx] }
                                                </SectionContext.Provider>
                                            </SubSection>
                                        ))}
                                    </div>
                                </div>
                            :
                                <>
                                    { header }
                                    <div class="content">
                                        { p.children }
                                    </div>
                                </>
                        }
                    </div>
                    { p.footer }
                </div>
            </PageContext.Provider>
        )
    }
}
Page.contextType = StyleContext

function SubSection(p) {
    return (
        <div id={p.id} class="subsection">
            { p.children }
        </div>
    )
}
   
export default Page