import React from 'react'
function PageHeader(p) {
    let flags = Object.assign({
        bulleted: true
    }, p)
    return (
        <div class="content">
            { flags.bulleted &&
                <img
                    class="page-bullet"
                    src="/images/siteV2/bullet-on.png"
                />
            }
            <div class="page-line-start" />
            <div class="page-title">
                <h3>{p.title}</h3>
                { p.subtitle &&
                    <div id="tagLine" dangerouslySetInnerHTML={{__html: p.subtitle}} />
                }
                { p.subsections &&
                    <div class="subsections-menu">
                        { p.subsections.map((subsection, idx)=>(
                            <a class="subsection-menu-item" key={idx}>{subsection.title}</a>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default PageHeader