class PageCounter {
    
    constructor(page=0) {
        this.page = page
    }

    next = () => {
        return this.page++
    }

    current = () => {
        return this.page
    }

}
   
export default PageCounter