import React from 'react'
import LandingPages from '../LandingPages'
import Hero from './pages/Hero'
import HeroTwo from './pages/HeroTwo'
import Challenges from './pages/Challenges'
import InsuranceAndReimbursement from './pages/InsuranceAndReimbursement'
import FormsAndSurveys from './pages/FormsAndSurveys'
import Rewards from './pages/Rewards'
import Messaging from './pages/Messaging'
import Scheduling from './pages/Scheduling'
import UniversalIntegration from './pages/UniversalIntegration'
import RequestDemo from './pages/RequestDemo'

var USER = 'user',
    DEMO = 'demo'

class Onboarding extends React.Component {
	constructor(props) {
		super(props)
    }

    render() {

        let title =<>
            <p><b>Make hiring and employee transitions easier!</b> With our best-in-class toolkit, Refresh provides forms, document upload, digital signature integration, training session scheduling, and benefit review for employees, all from their laptop, tablet, or smartphone.</p>
        </>

        return (
            <LandingPages submenu={true}>
                <Hero
                    title="Onboarding, Simplified!"
                    subtitle="Hire At Will"
                    blurb={title} />
                <HeroTwo
                    title="Effortlessly manage every aspect of your onboarding."
                    nextText="Forms + Surveys" />
                <FormsAndSurveys
                    inSubmenu={USER}
                    nextText="Universal Messaging" />
                <Messaging
                    inSubmenu={USER}
                    nextText="Employee Recognition + Challenges" />
                <Challenges
                    inSubmenu={USER}
                    nextText="Impressive Scheduling" />
                <Scheduling
                    inSubmenu={USER}
                    nextText="Universal Integration" />
                <UniversalIntegration
                    inSubmenu={USER}
                    nextText="Insurance + Reimbursement" />
                <InsuranceAndReimbursement
                    inSubmenu={USER}
                    nextText="Get a free demo" />

                <RequestDemo
                    inSubmenu={DEMO} />

            </LandingPages>
        )
    }
}

export default Onboarding