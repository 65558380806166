import React from 'react'

var DEMO_URL = 'https://calendly.com/refreshdemo/30min',
	DIV_CLASS = 'calendly-inline-widget'

class Calendly extends React.Component {
	componentDidMount() {

		// prepare listeners
		let completeFn = this.props.onComplete
		window.addEventListener(
			'message',
			e => {
				if(		isCalendlyEvent(e)
					&&	e.data.event == 'calendly.event_scheduled'
					&&	completeFn
				)
					completeFn()
			}
		)

		// init
		const head = document.querySelector('head')
		const script = document.createElement('script')
		script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
		head.appendChild(script)
		
		// setTimeout(()=>{
		// 	const initScript = document.createElement('script')
		// 	initScript.innerHTML = `
		// 		Calendly.initInlineWidget({
		// 			url: '${DEMO_URL}',
		// 			parentElement: document.getElementsByClassName('${DIV_CLASS}')[0],
		// 			utm: {
		// 				utmCampaign: "Spring Sale 2019",
		// 				utmSource: "Facebook",
		// 				utmMedium: "Ad",
		// 				utmContent: "Shoe and Shirts",
		// 				utmTerm: "Spring"
		// 			}         
		// 		})
		// 	`
		// 	head.appendChild(initScript)
		// }, 1000)
		
	}

	componentWillUnmount() {
		// whatever you need to cleanup the widgets code
	}

	shouldComponentUpdate(nextProps, nextState) {
		return false
	}

	render() {
		return (
			<div
				class={DIV_CLASS}
				data-url={`${DEMO_URL}?hide_event_type_details=1&background_color=484F77&text_color=ffffff&primary_color=D0DCFF`}
				style={{ minWidth: 320, height: 600 }} />
		)
	}
}

function isCalendlyEvent(e) {
	return e.data.event &&
		e.data.event.indexOf('calendly') === 0;
};

export default Calendly