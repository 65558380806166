import React from 'react'
import Card from '../Card'
import ImagePreload from '../ImagePreload'

function OnEveryPlatform({showVideoFn}) {
    return (
        <Card>
            <h4>...for any setting</h4>
            <div class="extra-wide ratio">Refresh is 100% device-agnostic, and we are accessible anywhere - as a custom iOS or Android app, mobile/desktop site, Slack or Teams integration, and beyond.</div>
            {/* <img id="service-map" class="graphic" src="/images/siteV2/service-map.png" /> */}
            <div
                id="vidPlatform"
                class="video ratio"
                onClick={()=>showVideoFn('https://refresh-videos.s3.amazonaws.com/public/customization+%26+onboarding.mp4')}
                style={{marginTop:0}}
            >
                <div class="info">
                    <div class="bg" />
                    <h3><span class="watch">Watch:</span> How it works</h3>
                </div>
            </div>
        </Card>
    )
}
   
export default OnEveryPlatform