import React from 'react'
import LandingPages from '../LandingPages'
import Hero from './pages/Hero'
import HeroTwo from './pages/HeroTwo'
import Messaging from './pages/Messaging'
import Scheduling from './pages/Scheduling'
import SocialRecognition from './pages/SocialRecognition'
import Challenges from './pages/Challenges'
import RequestDemo from './pages/RequestDemo'
import FormsAndSurveys from './pages/FormsAndSurveys'

var USER = 'user',
    DEMO = 'demo'

class Communication extends React.Component {
	constructor(props) {
		super(props)
    }

    render() {

        return (
            <LandingPages submenu={true}>
                
                <Hero 
                    blurb={ <>We help HR departments and managers <b>organize</b>, <b>integrate</b>, and <b>communicate</b> their ever-expanding health and benefits offering as they look to address the changing needs of their employees.</> }/>
                <HeroTwo
                    title="Effortlessly manage every aspect of employee communications."
                    nextText="Universal Messaging" />
                <Messaging
                    inSubmenu={USER}
                    nextText="Forms + Surveys" />
                <FormsAndSurveys
                    inSubmenu={USER}
                    nextText="Scheduling" />
                <Scheduling
                    inSubmenu={USER}
                    nextText="Social Recognition Platform" />
                <SocialRecognition
                    inSubmenu={USER}
                    nextText="Employee Challenges" />
                <Challenges
                    inSubmenu={USER}
                    nextText="Get a Demo" />
                <RequestDemo
                    inSubmenu={DEMO} />

            </LandingPages>
        )
    }
}

export default Communication