import React from 'react'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class NutritionTools extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(function(){
                runAnim(anims1, 1)
                runAnim(anims2, 1)
            },400)
        }
    }

    render() {
        return (
            <Card handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-nutrition-tools">
                <h4>Nutrition Tools</h4>
                <div>Encourage healthy eating habits. Employees learn incremental ways to change their diet and what to eat when dining out, and track nutrition across meals.</div>
                <div class="square ratio">
                    <div
                        id="phoneWellnessEduOne"
                        class="mockup phone"
                        content="/images/siteV2/mockup-init.jpg"
                    ></div>
                    <div
                        id="phoneWellnessEduTwo"
                        class="mockup phone"
                        content="/images/siteV2/mockup-init.jpg"
                    ></div>
                </div>
                <ImagePreload name="nutrition" anims={[anims1, anims2]} />
            </Card>
        )
    }
}
NutritionTools.contextType = PreloadContext

let anims1 = {

    target: '#phoneWellnessEduOne',
    wait: 4,
    anims: [
        {
            t:0,
            p:'m-restaurants'
        },
        {
            t:3,
            p:'m-restaurant'
        }
    ]
}

let anims2 = {

    target: '#phoneWellnessEduTwo',
    wait: 4,
    anims: [
        {
            t:.2,
            p:'m-eatthis-list'
        },
        {
            t:3.2,
            p:'m-eatthis-food'
        },
        
    ]
}
   
export default NutritionTools