import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class DashboardMessaging extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Card class="module-dashboard-messaging">
                <div>
                    <h4>Reach out across your organization</h4>
                    <div>Refresh is a multi-platform, multi-department solution, and our messaging tools reflect this diversity. Send rich templated emails to office employees or text messages to workers in the field.  Send push notifications directly to phones for higher engagement rates. Track messages sent and re-use message templates across segments.</div>
                </div>
                <Columns>
                    <Card>
                        <div class="square ratio">
                            <div
                                id="monitorMessagingOne"
                                class="mockup monitor"
                                content="/images/siteV2/m-messaging-sms-desktop.jpg"
                            ></div>
                        </div>
                    </Card>
                    <Card>
                        <div class="square ratio">
                            <div
                                id="monitorMessagingTwo"
                                class="mockup monitor"
                                content="/images/siteV2/m-messaging-email-desktop.jpg"
                            ></div>
                        </div>
                    </Card>
                </Columns>
                <ImagePreload name="dashboard-messaging" images={['m-messaging-sms-desktop.jpg', 'm-messaging-email-desktop.jpg']} />
            </Card>
        )
    }
}
   
export default DashboardMessaging