import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class DashboardPolling extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Card class="modules-dashboard-polling">
                <div class="extra-wide ratio">
                    <h4>Polling</h4>
                    <div>Manage and segment poll responses and take action to customize events and other programs to give employees what they want.</div>
                </div>
                <div class="wide ratio">
                    <div
                        id="phoneWorkflowsModulesPolls"
                        class="mockup monitor"
                        content="/images/siteV2/m-poll-desktop.jpg"
                    ></div>
                </div>
                <ImagePreload name="dashboard-messaging" images={['m-poll-desktop.jpg']} />
            </Card>
        )
    }
}
   
export default DashboardPolling