import React from 'react'
import LandingPage from '../../LandingPage'
import Columns from '../../Columns'

import HealthPlan from '../../cards/HealthPlan'
import Expenses from '../../cards/Expenses'

function InsuranceAndReimbursement(p) {
    return (
        <LandingPage id='insurance-and-reimbursement'
            title='Insurance + Reimbursement'
            handleAppearOnce={()=>{
                delayPeekSlidable( $('#insurance-slider') )
            }}
            {...p}
        >
            <Columns id="insurance-slider">
                <HealthPlan
                    title={<h2>Insurance Discovery</h2>}
                    body={<>Make it easy for your employees to <b>understand and utilize your health plan</b>. Our tool directly connects with over <b>900 health carriers</b> in the US giving employees real-time access to <b>coverages, deductibles, and consumables</b> like acupuncture, chiropractic care, and mental health services.</>}
                    {...p}
                />
                <Expenses
                    title={<h2>Wellness Reimbursement</h2>}
                    body={<>Our reimbursement tool is <b>fully customizable</b> to meet the needs of any organization. Tailor coverages to <b>specific users</b> or <b>population segments</b>. <b>Our AI bot will pre-approve receipts</b> and confirm amounts, <b>saving your team time and energy</b>. All that’s left to do is customize the export file and send it to payroll.</>}
                    {...p}
                />
            </Columns>
        </LandingPage>
    )
}
   
export default InsuranceAndReimbursement