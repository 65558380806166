import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import Screen from '../Screen'
import { PreloadContext } from '../../contexts'

class Communication extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(function(){
                runAnim(anims1, 1)
                runAnim(anims2, 1)
            },400)
        }
    }

    render() {
        let body = this.props.body || (
            <div>Communicate with individual segments of your employee population globally through push notifications, text messages, and emails. Target specific populations easily with the help of our backend data and analytics.</div>
        )
        return (
            <>
                <Columns
                    handleAppearOnce={()=>{
                        this.setState({visible:true})
                    }}
                    class="module-communication"
                    noSlide={true}
                    {...this.props}
                >
                    <div class="one-third">
                        <div class="hide-desktop">
                            { body }
                        </div>
                        <div class="threedee-frame square ratio hide-mobile">
                            <Screen
                                id="phoneCommunication"
                                content="mockup-init.jpg"
                            />
                        </div>
                    </div>
                    <div class="two-thirds hide-mobile">
                        { body }
                        <div class="module-communication-monitor-frame">
                            <div class="threedee-frame wide ratio">
                                <Screen
                                    id="monitorCommunication"
                                    type="monitor"
                                    content="mockup-imac-init.jpg"
                                />
                            </div>
                        </div>
                    </div>
                </Columns>
                <div class="callouts hide-mobile" id="commCallouts">
                    <div class="callout" id="commCalloutYoga">
                        ...send out a targeted notice to your Minneapolis office to everyone who recently attended a class.
                    </div>
                    <div class="callout" id="commCalloutBowling">
                        ...let the Cleveland accounting team know about the upcoming team bowling event and have them sign up.
                    </div>
                </div>
                <ImagePreload name="comms" anims={[anims1, anims2]} />
            </>
        )
    }
}
Communication.contextType = PreloadContext

let anims1 = {

    target: '#phoneCommunication',
    wait: 3,
    anims: [
        {
            t:1,
            a:true,
            p:'m-phone-homescreen',
            ping:{
                delay:0
            },
            callout: 'null'
        },
        {
            t:3,
            p:'m-notice-wallet',
            callout: 'commCalloutYoga'
        },
        {
            t:7,
            p:'m-notice-bowling',
            callout: 'commCalloutBowling'
        },
        {
            t:11,
            p:'mockup-init',
            callout: 'null'
        }
        
    ]
}

let anims2 = {

    target: '#monitorCommunication',
    wait: 3,
    anims: [
        {
            t:0,
            p:'m-messaging-wallet-desktop'
        },
        {
            t:7,
            p:'m-messaging-bowling-desktop'
        },
    ]
}
   
export default Communication