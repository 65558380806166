'use strict'
import React from 'react'
import ReactDOM from 'react-dom'
import Contexts from './contexts'

import Home from './views/Home'
import HomeNew from './views/HomeNew'
// landing pages
import Companies from './views/Companies'
import Communication from './views/landings/Communication'
import Wellness from './views/landings/Wellness'
import BenefitsDiscovery from './views/landings/BenefitsDiscovery'
import EmployeeRecognition from './views/landings/EmployeeRecognition'
import Onboarding from './views/landings/Onboarding'
import ReturnToWork from './views/landings/ReturnToWork'
// blog
import Blog from './views/Blog'
import BlogIndex from './views/BlogIndex'

const CONTAINER_SEL = '#refreshModularPage'

const pageModules = {
	Home: HomeNew,
	// Home,
	Companies,
	Communication,
	Wellness,
	BenefitsDiscovery,
	EmployeeRecognition,
	Onboarding,
	ReturnToWork,
	Blog,
	BlogIndex
}

class Refresh extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
		if(!window.destTriggers)
            window.destTriggers = {}
	}

	componentDidMount() {
		if(typeof _testClasses != 'undefined' )
			_testClasses.map( cls => $('body').addClass(cls) )
	}

	render() {

		let PageModule = pageModules[window.pageModule] || Companies

		return (
			<PageModule />
		)
	}
}

const domContainer = document.querySelector(CONTAINER_SEL)
ReactDOM.render( React.createElement(Refresh), domContainer )