import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class DashboardAnalytics extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <Card class="module-dashboard-analytics">
                <div>
                    <h4>Find meaning and improve your programs</h4>
                    <div>The Dashboard features a rich analytics tool for fine-tuning your wellness amenities and communications. See who’s joining what, watch for seasonal changes, and track app usage across segments. Export any information you’d like to CSV, Excel, and PDF formats.</div>
                </div>
                <Columns>
                    <Card>
                        <div class="threedee-frame square ratio">
                            <div
                                id="monitorAnalyticsSignups"
                                class="mockup monitor"
                                content="/images/siteV2/m-analytics-signups.jpg"
                            ></div>
                        </div>
                    </Card>
                    <Card>
                        <div class="threedee-frame square ratio">
                            <div
                                id="monitorAnalyticsClasses"
                                class="mockup monitor"
                                content="/images/siteV2/m-analytics-events.jpg"
                            ></div>
                        </div>
                    </Card>
                </Columns>
                <ImagePreload name="dashboard-analytics" images={['m-analytics-signups.jpg', 'm-analytics-events.jpg']} />
            </Card>
        )
    }
}
   
export default DashboardAnalytics