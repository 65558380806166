import React from 'react'
import Page from '../Page'

function MainHero(p) {
    return (
        <Page
            id='mainHero'
            bulleted={false}
            custom={true}
            extraAttr={{
                bg: '/images/siteV2/conductor2.mp4',
                bgopacity: '.9'
            }}
            classes={{
                'vid-bg': true,
                'show-on-landing': true
            }}
            idx={p.idx}
        >
            <div class="content">
                <h1>Compose Your Experiences</h1>
                <h2>A platform for the<br class="hide-mobile"/> Modern Enterprise</h2>
                <div class="page-line-start" />
            </div>
        </Page>
    )
}
   
export default MainHero