import React from 'react'
import {
    PageContext,
    SectionContext
} from '../contexts'
import registerTriggers from '../Triggers'

class Card extends React.Component {
    
    constructor(props) {
		super(props)
    }

    componentDidMount() {
        registerTriggers(this)
    }

    render() {
        return (
            <PageContext.Consumer>{(pageContext) => (
                <SectionContext.Consumer>{(sectionContext) => (
                    <div class={`card ${this.props.class}`}
                        ref={r=>{
                            this.pageId=pageContext && pageContext.pageId
                            this.sectionId=sectionContext && sectionContext.sectionId
                        }}
                    >
                        {this.props.children}
                    </div>
                )}</SectionContext.Consumer>
            )}</PageContext.Consumer>
        )
    }
}
   
export default Card