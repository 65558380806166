import React from 'react'
import Card from '../Card'

class ModularSolution extends React.Component {

    constructor(props) {
        super(props)
        this.animId = Math.round(Math.random()*100000)
    }

    componentDidMount() {
        if(this.props.autoStart)
            this.init()
    }

    init = () => {
        loadBodyMovin({id: this.animId, file: 'modular.json', loop: true})
    }

    render() {
        return (
            <Card handleAppearOnce={()=>this.init()}>
                {!this.props.noText &&
                    <>
                        {this.props.title || <h4>A Modular Solution</h4>}
                        <div class="extra-wide ratio">
                            {this.props.body || `Completely customizable, from functionality to appearance. Mix-and-match features to perfectly suit employees' needs, by office, team, or individual. A fully white-labeled app fits seamlessly into your organization.`}
                        </div>
                    </>
                }
                <div 
                    id={this.animId}
                    class="graphic animation"
                    style={{marginTop:-60}}
                ></div>
            </Card>
        )
    }
}
   
export default ModularSolution