import React from 'react'
import Page from '../Page'
import LiveServices from '../cards/LiveServices'
import AppPlatform from '../cards/AppPlatform'
import VideoSeries from '../cards/VideoSeries'
import NutritionTools from '../cards/NutritionTools'
import River from '../River'
import Columns from '../Columns'

function Wellness(p) {
    return (
        <Page 
            id='wellness'
            title='Wellness'
            subsections={[
                { title: 'Amenities ', id:'amenities', river:'animServicesRiver'},
                { title: 'Education', id:'education' }
            ]}
            handleAppearOnce={()=>{
                servicesAnimPaused = false
                delayPeekSlidable( $('#wellness-slider') )
                setTimeout(initServicesAnim,0)
            }}
            {...p}
        >
            <>
                <River id="animServicesRiver" />
                <Columns
                    id="wellness-slider"
                >
                    <LiveServices {...p} />
                    <AppPlatform {...p} />
                </Columns>
            </>
            <Columns
                id="wellness-slider"
            >
                <VideoSeries {...p} />
                <NutritionTools {...p} />
            </Columns>
        </Page>
    )
}
   
export default Wellness