import React from 'react'
import LandingPage from '../../LandingPage'
import SocialRecognitionCard from '../../cards/SocialRecognition'

function SocialRecognition(p) {
    return (
        <LandingPage
            id="social"
            title="Social Recognition"
            {...p}
        >
            <SocialRecognitionCard
                title={<h2>{p.title || 'Social Recognition Platform'}</h2>}
                {...p}
            />
        </LandingPage>
    )
}
   
export default SocialRecognition