import React from 'react'
class Footer extends React.Component {
    
    constructor(props) {
		super(props)
    }

    render() {
        return (
            <div class="page-block-lower footer">
                <div class="footer-menu">
                    {/* <a class="footer-item" href="/join-our-team">Join Our Team</a>
                    <a class="footer-item" href="/about">About Us</a>
                    <a class="footer-item" href="/contact-us">Contact</a>
                    <a class="footer-item" href="/terms">Terms of Service</a>
                    <a class="footer-item" href="/privacy">Privacy Policy</a> */}
                    <a class="footer-item" href="https://blog.refreshplatform.com">Blog: Knowledge Base</a>
                </div>
            </div>
        )
    }
}

export default Footer