import React from 'react'
function River(p) {
    return (
        <div>
            <div
                id={p.id}
                class="wide ratio"
                style={{top:180}}
            ></div>
        </div>
    )
}
export default River