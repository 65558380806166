import React from 'react'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class HealthPlan extends React.Component {
    
    constructor(props) {
		super(props)
        this.state = {}
    }
    
    init = () => {
        runAnim(anims, 3)
    }

    render() {
        return (
            <Card handleAppearOnce={()=>this.init()} class="module-healthplan">
                {this.props.title || <h4>Health Plan Explorer</h4>}
                <div>
                    {this.props.body || `What’s covered? What’s not? This common employee question is answered in our integrated health care plan tool, which connects directly with your insurer and provides plan details, ID card-to-wallet, and contact information.`}
                </div>
                <div class="square ratio">
                    <div
                        id="phoneDiscoveryInsurance"
                        class="mockup phone expandable left-info"
                        content="/images/siteV2/mockup-init.jpg"
                    ></div>
                </div>
                <ImagePreload name="healthplan" anims={[anims]} />
            </Card>
        )
    }
}
HealthPlan.contextType = PreloadContext

var anims = {

    target: '#phoneDiscoveryInsurance',
    wait: 2,
    anims: [
        {
            t:1,
            p:'m-benefits-card'
        },
        {
            t:3,
            p:'m-benefits-coverage'
        },
        {
            t:5,
            p:'m-benefits-costs'
        }
    ]
}
   
export default HealthPlan