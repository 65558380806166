import React from 'react'

import Header from './Header'
import DemoBox from './landings/DemoBox'
import blogUtils from '../blogUtils'

class BlogIndex extends React.Component {
	constructor(props) {
		super(props);
        this.state = { images: {} }
    }

    componentDidMount() {
        $('body').append( $('<style>.v2 { height: auto; }</style>') )

        let load = () => {
            this.loadPage({
                url: `https://docs.google.com/spreadsheets/d/e/2PACX-1vQrb_HHUpSMxXJKXT5UFeZ1E4dzC6H7APSlN6jEQK3vP-4wgaOHZeNyAA4hDqg0eAdclo6ANvKyUziI/pub?gid=0&single=true&output=csv`
            })
        }
        load()
        // setInterval(load, 5*1000)

        addAllStyles()
        postBuild()
    }

    loadPage({url}) {
        $.get(url, content=>this.scrapeContent(content))
        console.log('loadPage')
    }

    scrapeContent(raw) {
        let rows = raw.split('\n')
        rows.shift()
        rows = rows
            .map( row => row.split(',') )
            .map( row => ({
                title: row[0],
                image: row[1],
                url: row[2]
            }))
        console.log(rows)
        let contents = rows
        this.setState({ contents })
    }

    openDemo = () => {
        this.setState({ demoOpen: true })
        if(pageView)
            pageView(null, '#demoRequestPopupOpen')
    }

    closeDemo = () => {
        this.setState({ demoOpen: false })
        if(pageView)
            pageView(null, '#demoRequestPopupClose')
    }

    render() {
        let { title, images } = this.state
        return (
            <>
                <Header
                    openDemo={this.openDemo}
                    // wide={true}
                    pagesMenu={true}
                    // burgerMenu={this.props.burgerMenu}
                />
                <div class="main-header-size" />
                <div class="blog">

                    <div class="blog-hero">
                        <div class="bg"
                            style={{backgroundImage: `url("https://docs.google.com/spreadsheets/d/e/2PACX-1vQrb_HHUpSMxXJKXT5UFeZ1E4dzC6H7APSlN6jEQK3vP-4wgaOHZeNyAA4hDqg0eAdclo6ANvKyUziI/pub?gid=0&single=true&output=csv")` }}
                        />
                        <div class="content">
                            <h5 class="blog-title">The Refresh Blog:</h5>
                            <h1 class="title">Knowledge Base</h1>
                        </div>
                    </div>
                    { this.state.contents &&
                        <div class="content main-content-frame">
                            {this.state.contents.map((item, idx)=>(
                                <a class="blog-block" href={item.url} index={idx} style={{backgroundImage: `url('${item.image}')`}}>
                                    <h4 class="text">{item.title}</h4>
                                </a>
                            ))}
                        </div>
                    }
                </div>

                <DemoBox open={this.state.demoOpen} closeFn={this.closeDemo} />
            </>
        )
    }
}

export default BlogIndex