import React from 'react'
import Columns from '../../Columns'
import LandingPage from '../../LandingPage'
import Communication from '../../cards/Communication'

function Messaging(p) {
    return (
        <LandingPage
            id="messaging"
            title="Messaging"
            {...p}
        >
            <div class="columns-2" style={{whiteSpace:'nowrap', alignItems:'baseline'}}>
                <div class="one-third" style={{marginRight:0}}>
                    <h2>Universal Messaging</h2>
                </div>
                <div class="two-thirds hide-mobile">
                    <h3>All-mode communication at its best</h3>
                </div>
            </div>
            <div class="landing-page-messaging-body">
                <Communication
                    body={(
                        <>
                            <Columns
                                class="landing-page-messaging-columns"
                                noSlide={true}
                            >
                                <div>
                                    <p><b>Tailor your reach</b> to <b>geographies</b>, <b>behavioral groups</b>, and <b>individuals</b> with intelligent tools for sending and receiving across channels:</p>
                                    <div
                                        // class="feature-bullets feature-grid"
                                        class="feature-bullets"
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 22
                                        }}
                                    >
                                        <div class="dynamic-email">Dynamic email</div>
                                        <div class="slack-integration">Slack integration</div>
                                        <div class="push-notifications">Push notifications</div>
                                        <div class="text-messaging">Text messaging</div>
                                        <div class="bespoke-newsfeeds">Bespoke newsfeeds</div>
                                    </div>
                                </div>

                                <div>
                                    <p><b>Manage gracefully</b> with powerful dashboard features:</p>
                                    <div
                                        // class="feature-bullets feature-grid"
                                        class="feature-bullets"
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 32
                                        }}
                                    >
                                        <div class="channel-management">Intelligent routing</div>
                                        <div class="message-templates">Message templates</div>
                                        <div class="send-and-receive-history">Send and receive history</div>
                                        <div class="engagement-tracking">Usage tracking</div>
                                        <div class="custom-integration">Custom integrations</div>
                                    </div>
                                </div>
                            </Columns>
                        </>
                    )}
                    {...p}
                />
            </div>
        </LandingPage>
    )
}
   
export default Messaging