import React from 'react'
const PageContext = React.createContext()
const SectionContext = React.createContext()
const PreloadContext = React.createContext()

let style = {
    pagesAlignTop: true,
    heroAlignTop: true
}
if(typeof _testContext != 'undefined')
    style = Object.assign(style, _testContext)
const StyleContext = React.createContext(style)

export {
    PageContext,
    SectionContext,
    PreloadContext,
    StyleContext
}