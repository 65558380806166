import React from 'react'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

var anims = {

    target: '#monitorWellnessEduVideo',
    wait: 18,
    anims: [
        {
            t:0,
            p:'BUTTERFLY_STRETCH',
            v:true
        },
        {
            t:20,
            p:'m-routinebuilder-desktop'
        },
        {
            t:23,
            p:'m-playlist-desktop'
        }
    ]
}

class VideoSeries extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preload && this.state.visible && !this.didInit)
        {
            this.didInit = true
            runAnim(anims, 1)
        }
    }

    render() {
        let p = this.props
        return (
            <Card handleAppearOnce={()=>{
                this.setState({visible:true})
            }}>
                {p.title || <h4>Video and Audio Series</h4>}
                {p.body || <div>Our in-house series guides employees through basic movements they can do at their desk to alleviate stress, fitness routines, guided audio meditation -  even financial wellness, in a series from Fidelity™.</div>}
                <div
                    id="monitorWellnessEduVideo"
                    class="mockup monitor"
                    content="/images/siteV2/mockup-imac-init.jpg"
                    style={{marginTop:30}}
                ></div>
                <ImagePreload name="video" anims={[anims]} />
            </Card>
        )
    }
    
}
VideoSeries.contextType = PreloadContext
   
export default VideoSeries