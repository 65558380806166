import React from 'react'
import LandingPage from '../../LandingPage'
import ChallengesCard from '../../cards/Challenges'

function Challenges(p) {
    return (
        <LandingPage
            id="challenges"
            title="Employee Recognition + Challenges"
            {...p} >
            
            <ChallengesCard
                {...p}
                title={<h2>{p.title || `Employee Recognition and Challenges`}</h2>}
                body={(
                    <>
                        {/* <p>
                            With our employee recognition and challenges system, <b>any point of interaction can be incentivized</b>. Reward employees for completing <b>e-learning</b> or reading <b>daily notices</b>; <b>jump-start exercise</b> and promote heart health with <b>step tracking</b>, wearables (<b>FitBit</b>, <b>Apple Watch</b>), and Cardiogram™ integration; run competitions in <b>fitness</b> or learning tasks across teams. You can build a <b>points-based system</b>, deliver <b>weekly or monthly prizes</b>, create <b>seasonal award campaigns</b>, and much more - directly through the app.
                            <img class="wearable" src="/images/siteV2/landing-wearable.png"/>
                        </p> */}

                        <p>With our employee recognition and challenges system, <b>any point of interaction can be incentivized:</b></p>
                        <div
                            class="feature-bullets"
                            style={{
                                marginTop: 15,
                                marginBottom: 22
                            }}
                        >
                            <div class="achievements"><b>Reward employees</b> for completing <b>e-learning</b> or reading <b>daily notices</b></div>
                            <div class="step-tracking"><b>Jump-start exercise</b> and promote heart health with <b>step tracking</b></div>
                            <div class="wearables"><b>Wearables</b> (<b>FitBit</b>, <b>Apple Watch</b>), and Cardiogram™ integration</div>
                            <div class="fitness">Run competitions in <b>fitness</b> or learning tasks across teams</div>
                            <div class="rewards">Build a <b>points-based system</b>, deliver <b>weekly or monthly prizes</b>, create <b>seasonal award campaigns</b>, and much more</div>
                        </div>
                        <img class="wearable" src="/images/siteV2/landing-wearable.png"/>
                    </>
                )}
            />
        </LandingPage>
    )
}
   
export default Challenges