import React from 'react'
import LandingPages from '../LandingPages'
import LandingPage from '../LandingPage'
import Hero from './pages/Hero'
import HeroTwo from './pages/HeroTwo'
import WellnessPage from './pages/Wellness'
import InsuranceAndReimbursement from './pages/InsuranceAndReimbursement'
import Messaging from './pages/Messaging'
import Scheduling from './pages/Scheduling'
import Challenges from './pages/Challenges'
import FormsAndSurveys from './pages/FormsAndSurveys'
import RequestDemo from './pages/RequestDemo'

var USER = 'user',
    DEMO = 'demo'

class Wellness extends React.Component {
	constructor(props) {
		super(props)
    }

    render() {

        return (
            <LandingPages submenu={true}>
                {!_isInternal &&
                    <Hero
                        title="Wellness, Anywhere!"
                        subtitle="The Best-In-Class Solution"
                        blurb={
                            <>
                                <span class="hide-mobile"><b>Get to it!</b> Deliver a program that meets needs and objectives while providing a rich experience for end users.</span> Featuring <b>🧘<a href="#wellness-amenities">live + recorded digital fitness classes</a></b>, ⌚️ <b><a href="#challenges">FitBit/Apple Watch</a></b> and other wearable integration, <b>💗 <a href="#insurance-and-reimbursement">benefits discovery</a></b> tools, <b>🥇 <a href="#challenges">employee recognition + rewards</a></b>, <b>📫 <a href="#messaging">robust communication features</a></b> and <b>📐 <a href="#analytics">detailed analytics</a></b>, Refresh inspires and enables employee wellness, wherever they may be.
                            </>
                        } />
                }
                <HeroTwo
                    title="Effortlessly manage every aspect of your wellness program."
                    nextText="Live and Digital Sessions" />
                <WellnessPage
                    inSubmenu={USER}
                    nextText="Employee Recognition + Wearables" />
                <Challenges
                    inSubmenu={USER}
                    title="Employee Recognition + Wearables"
                    nextText="Insurance and Reimbursement" />
                <InsuranceAndReimbursement
                    inSubmenu={USER}
                    nextText="Universal Messaging" />
                <Messaging
                    inSubmenu={USER}
                    nextText="Impressive Scheduling" />
                <Scheduling
                    inSubmenu={USER}
                    nextText="Forms and Surveys" />
                <FormsAndSurveys
                    inSubmenu={USER}
                    nextText="Get a free demo" />

                <RequestDemo
                    inSubmenu={DEMO} />

            </LandingPages>
        )
    }
}

export default Wellness