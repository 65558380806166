import React from 'react'
import LandingPages from '../LandingPages'
import Hero from './pages/Hero'
import HeroTwo from './pages/HeroTwo'
import UniversalIntegration from './pages/UniversalIntegration'
import InsuranceAndReimbursement from './pages/InsuranceAndReimbursement'
import FormsAndSurveys from './pages/FormsAndSurveys'
import Rewards from './pages/Rewards'
import Messaging from './pages/Messaging'
import Scheduling from './pages/Scheduling'
import RequestDemo from './pages/RequestDemo'

var USER = 'user',
    DEMO = 'demo'

class BenefitsDiscovery extends React.Component {
	constructor(props) {
		super(props)
    }

    render() {

        let blurb =
            <>
                <p>Empower your employees with a <b>single, personalized resource</b> to engage with your company’s entire benefits ecosystem. <b>Tailor content</b>, <b>track usage</b> and <b>communicate directly</b> with employees around new or under-used benefits and services, and much more.</p>
                {/* <p class="hide-mobile">Our <b>data analytics tool</b> can be tailored to <b>serve up the most relevant information</b> to employees based on their demographics, benefits band, etc.</p> */}
            </>

        return (
            <LandingPages submenu={true}>
                <Hero
                    title="Next-level Benefits"
                    subtitle="Best-in-class discovery and admin"
                    blurb={blurb} />
                <HeroTwo
                    title="Empower your employees with a single, personalized resource to engage with your company’s entire benefits ecosystem."
                    nextText="Universally Integrated" />
                <UniversalIntegration
                    inSubmenu={USER}
                    nextText="Insurance + Reimbursement" />
                <InsuranceAndReimbursement
                    inSubmenu={USER}
                    nextText="Forms + Surveys" />
                <FormsAndSurveys
                    inSubmenu={USER}
                    nextText="Universal Messaging" />
                <Messaging
                    inSubmenu={USER}
                    nextText="Impressive Scheduling" />
                <Scheduling
                    inSubmenu={USER}
                    nextText="Employee Discounts + Perks" />
                <Rewards
                    inSubmenu={USER}
                    nextText="Get a free demo" />

                <RequestDemo
                    inSubmenu={DEMO} />

            </LandingPages>
        )
    }
}

export default BenefitsDiscovery