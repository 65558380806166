import React from 'react'
import LandingPages from '../LandingPages'
import Hero from './pages/Hero'
import HeroTwo from './pages/HeroTwo'
import Challenges from './pages/Challenges'
import SocialRecognition from './pages/SocialRecognition'

import InsuranceAndReimbursement from './pages/InsuranceAndReimbursement'
import FormsAndSurveys from './pages/FormsAndSurveys'
import Rewards from './pages/Rewards'
import Messaging from './pages/Messaging'
import Scheduling from './pages/Scheduling'
import RequestDemo from './pages/RequestDemo'

var USER = 'user',
    DEMO = 'demo'

class EmployeeRecognition extends React.Component {
	constructor(props) {
		super(props)
    }

    render() {

        let blurb = <>
                            <p>Our combination of <b>dynamic challenges</b>, <b>meaningful rewards</b>, <b>social connections</b>, and <b>multi-channel messaging</b> allows you to provide a more connected, holistic experience to your employees, directly engaging them in your company’s culture, core values and helping them be their most productive.</p>
                        </>

        return (
            <LandingPages submenu={true}>
                <Hero
                    title="Employee Recognition, Anywhere!"
                    subtitle="Refresh: Recognized"
                    blurb={blurb}  />
                <HeroTwo
                    title="Provide a connected, holistic experience to your employees, directly engaging them in your company’s culture and core values and helping them be their most productive."
                    nextText="Employee Recognition + Challenges" />
                <Challenges
                    inSubmenu={USER}
                    nextText="Social Recognition Platform" />
                <SocialRecognition
                    inSubmenu={USER}
                    nextText="Forms + Surveys" />
                <FormsAndSurveys
                    inSubmenu={USER}
                    nextText="Employee Discounts + Perks" />
                <Rewards
                    inSubmenu={USER}
                    nextText="Universal Messaging" />
                <Messaging
                    inSubmenu={USER}
                    nextText="Impressive Scheduling" />         
                <Scheduling
                    inSubmenu={USER}
                    nextText="Get a free demo" />
                <RequestDemo
                    inSubmenu={DEMO} />

            </LandingPages>
        )
    }
}

export default EmployeeRecognition