import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

let anims1 = {

    target: '#phoneChallenges',
    wait: 2,
    anims: [
        {
            t:0,
            p:'m-challenges'
        },
        {
            t:2,
            p:'m-challenge-leaders'
        },
        {
            t:4,
            p:'m-cardiogram'
        }
    ]
}

class Challenges extends React.Component {

    constructor(props) {
		super(props)
        this.state = {}
    }
    
    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(function(){
                turnIn( $('#phoneChallenges') )
                runAnim(anims1, 3)
                delayPeekSlidable( $('#challenges-slider') )
            },400)
        }
    }

    render() {
        return (
            <>
            <Columns handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-challenges" id="challenges-slider">
                <Card>
                    {this.props.title ||
                        <h4>Drive Engagement</h4>
                    }
                    {this.props.body ||
                        <div>Incentivize participation across the platform with Refresh Challenges. Reward employees for watching videos, completing e-learning, or reading daily notices; jump-start exercise and promote heart health with step tracking, wearables (FitBit, Apple Watch), and Cardiogram™ integration; run competitions in fitness or learning tasks across teams.</div>
                    }
                    {/* <div id="challengesAnim" class="graphic animation" /> */}
                    <div style={{textAlign:'center'}}>
                        <img id="challengesStatic" src="/images/siteV2/challenges-static.png" />
                    </div>
                </Card>
                <Card>
                    <div class="threedee-frame square ratio">
                        <div
                            id="phoneChallenges"
                            class="mockup phone will-turn-in expandable"
                            content="/images/siteV2/mockup-init.jpg"
                        ></div>
                    </div>
                </Card>
            </Columns>
            <ImagePreload name="challenges" anims={[anims1]} />
            </>
        )
    }
}
Challenges.contextType = PreloadContext
   
export default Challenges