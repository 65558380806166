import React from 'react'
import LandingPage from '../../LandingPage'
import DemoBox from '../DemoBox'
import Footer from '../../Footer'

function RequestDemo(p) {
    return (
        <LandingPage
            id="demoRequest"
            title="See it in action"
            footerChildren={ <Footer /> }
            hideDemo={true}
            classes={{
                'wires-bg': false,
                'dark': true,
                'align-top': false
            }}
            {...p}
        >
            <div class="demo-page-box">
                <DemoBox standalone="true" {...p}/>
            </div>
        </LandingPage>
    )
}
   
export default RequestDemo