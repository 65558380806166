import React from 'react'
import { StyleContext } from '../../../contexts'
import LandingPage from '../../LandingPage'
import {
    CSSTransition,
    TransitionGroup
} from 'react-transition-group'

const TESTIMONIALS = [
        {
            quote: `Refresh replaced 6 different apps we were running at almost one-sixth the cost. And it’s much easier to use.`,
            name: `Perry K.`,
            title: `Senior Director of HR`
        },
        {
            quote: `This combination of user-friendly tools and deeper analytics has made my process so much simpler.`,
            name: `Sarah R.`,
            title: `VP, People and Culture`
        },
        {
            quote: `Where Refresh shines above the rest is in how seamless it is from a workflow perspective.`,
            name: `Alex S.`,
            title: `Sr. Benefits Coordinator`
        } 
    ],
    TIMING = 5000
var hasShown = false
var testimonialIndex = 0

class Hero extends React.Component {

    constructor(props) {
		super(props)
        this.state = {
            showTestimonial: true,
            testimonial: TESTIMONIALS[testimonialIndex]
        }
    }

    componentDidMount() {
        setInterval(()=>this.nextTestimonial(), TIMING)
    }

    nextTestimonial() {
        if( testimonialIndex >= TESTIMONIALS.length-1)
            testimonialIndex = 0
        else
            testimonialIndex++
        this.setTestimonal(testimonialIndex)
    }

    setTestimonal(idx) {
        this.setState({ showTestimonial:false })
        setTimeout(
            () => this.setState({
                testimonial: TESTIMONIALS[idx],
                showTestimonial:true
            }),
            800
        )
        
    }

    render() {
        let p = this.props
        if(!hasShown)
        {
            setTimeout(()=>{
                let offset = 0,
                    places = ['office', 'home', 'camping', 'space']
                places.map(name=>{
                    setTimeout(()=>{
                        $(`#landing-places-${name}`).removeClass('offstage')
                        setTimeout(()=>{
                            $(`#landing-places-${name} .greeting`).fadeIn()
                        }, 1200)
                    }, offset)
                    offset += 600
                })
            }, 1200)
        }

        let t = this.state.testimonial || {},
            testimonials = (
            <div id="landing-testimonials" class="banner hide-mobile">
                    <CSSTransition
                        in={this.state.showTestimonial}
                        classNames="testimonial"
                        timeout={0}
                    >
                        <div class="content" key="content">
                            <div class="testimonial-quote">“{t.quote}”</div>
                            <div class="testimonial-attribution">
                                <img class="five-stars" src="/images/siteV2/five-stars.png" />
                                <div>{t.name}</div>
                                <div>{t.title}</div>
                            </div>
                        </div>
                    </CSSTransition>
            </div>
        )
        let clients = (
            <div id="landing-clients" class="banner">
                <div class="content hide-mobile">
                    <img id="tag-happy-clients" src="/images/siteV2/tag-happy-clients.svg" />
                </div>
                <div class="bg-image landing-clients-img hide-mobile" />
                <div class="bg-image landing-clients-mobile-img hide-desktop" />
            </div>
        )
        return (
            <LandingPage
                id="intro"
                classes={{
                    'wires-bg': false,
                    'dark': true,
                    'align-top': this.context.heroAlignTop
                }}
                wideContent={!this.context.heroAlignTop && (
                    <>
                        {testimonials}
                        {clients}
                    </>
                )}
                footerChildren={this.context.heroAlignTop && clients}
                {...p}
            >
                <h1>
                    {p.title || <>The Anywhere Office</>}
                </h1>
                <h2>
                    {p.subtitle || <>Refresh: Elevated Communications</>}
                </h2>
                <div class="blurb spacey-text">
                    {p.blurb}
                </div>
                <div class="landing-places-shim" style={{position:'relative', left:'50%'}}>
                    {/* <img
                        id="landing-places"
                        src="/images/siteV2/places-static.svg"
                    /> */}
                    <div id="landing-places">
                        <div class="landing-place offstage" id="landing-places-office">
                            <img src="/images/siteV2/landing-places-office.svg" />
                            <img class="greeting" src="/images/siteV2/landing-places-office-callout.svg" />
                        </div>
                        <div class="landing-place offstage" id="landing-places-home">
                            <img src="/images/siteV2/landing-places-home.svg" />
                            <img class="greeting" src="/images/siteV2/landing-places-home-callout.svg" />
                        </div>
                        <div class="landing-place offstage" id="landing-places-camping">
                            <img src="/images/siteV2/landing-places-camping.svg" />
                            <img class="greeting" src="/images/siteV2/landing-places-camping-callout.svg" />
                        </div>
                        <div class="landing-place offstage" id="landing-places-space">
                            <img src="/images/siteV2/landing-places-space.svg" />
                            <img class="greeting" src="/images/siteV2/landing-places-space-callout.svg" />
                        </div>
                    </div>
                </div>
                
            </LandingPage>
        )
    }
    
}
Hero.contextType = StyleContext
   
export default Hero