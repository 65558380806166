import React from 'react'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

var anims1 = {

    target: '#phoneDiscoveryExpensesOne',
    wait: 2,
    anims: [
        {
            t:1.5,
            p:'m-rebur-add'
        },
        {
            t:3.5,
            p:'m-rebur-list'
        },
        {
            t:5.5,
            p:'m-rebur-edit'
        }
    ]
}

var anims2 = {

    target: '#monitorDiscoveryExpensesTwo',
    wait: 2,
    anims: [
        {
            t:2,
            p:'m-expenses-config-desktop'
        },
        {
            t:4,
            p:'m-expenses-list-desktop'
        }
    ]
}

class Expenses extends React.Component {
    
    constructor(props) {
		super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            runAnim(anims1, 3)
            runAnim(anims2, 3)
        }
    }

    render() {
        return (
            <Card handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-expenses">
                {this.props.title || <h4>Wellness Expense Workflow</h4>}
                <div>
                    {this.props.body || <>
                        <p>Many employers provide reimbursement for self-directed wellness expenses such as gym memberships, smoking cessation, nutrition counseling, and more.</p>
                        <p>Refresh facilitates expense submission and tracking, and manager tools handle the fulfillment workflow.</p>
                    </>}
                </div>
                <div class="square ratio">
                    <div
                        id="monitorDiscoveryExpensesTwo"
                        class="mockup monitor"
                        content="/images/siteV2/mockup-imac-init.jpg"
                    ></div>
                    <div
                        id="phoneDiscoveryExpensesOne"
                        class="mockup phone"
                        content="/images/siteV2/mockup-init.jpg"
                    ></div>
                </div>
                <ImagePreload name="expenses" anims={[anims1, anims2]} />
            </Card>
        )
    }
}
Expenses.contextType = PreloadContext
   
export default Expenses