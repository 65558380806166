import React from 'react'

function DemoVideo(props) {
	return (
		<div style={{
			position:'fixed',
			top:0, right:0, bottom:0, left:0,
			backgroundColor: 'rgba(0,0,0,.5)',
			zIndex:1000
		}} onClick={props.closeFn}>
			<video src={props.videoSrc}
				style={{
					maxWidth:'90%', maxHeight:'90%',
					position:'absolute',
					left:'50%', top:'50%',
					transform: 'translate(-50%, -50%)',
					// padding:40,
					backgroundColor:'white', boxShadow:'rgba(0,0,0,.5) 0px 0px 40px'
				}}
				autoplay="autoplay"
			/>
		</div>
	)
}

export default DemoVideo