import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

let anims = {

    target: '#phoneWellnessDiscoveryRewards',
    wait: 2,
    anims: [
        {
            t:1,
            p:'m-rewards-list'
        },
        {
            t:2.5,
            p:'m-rewards-reward'
        },
        {
            t:4,
            p:'m-rewards-discounting'
        },
        {
            t:5.5,
            p:'m-rewards-purchased'
        }
    ]
}

class Rewards extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            turnIn( $('#phoneWellnessDiscoveryRewards') )
            runAnim(anims, 3)
            setTimeout(function(){
                loadBodyMovin({id: 'rewardsAnim', file: 'rewards.json'})
            },400)
        }
    }

    render() {
        return (
            <>
            <Columns handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-rewards">
                <Card>
                    {this.props.title || <h4>Employee Rewards Engine</h4>}
                    <div><b>Offer exclusive discounts</b> through local retailers. Gift game-day tickets to <b>reward sales goals</b> or for employee birthdays. Tie rewards to completed Challenges to <b>drive participation</b>. Along with a <b>partnership with RewardJet™</b>, the Refresh Rewards engine can co-exist with or <b>jump-start your employee rewards program.</b></div>
                    <div id="rewardsAnim" class="graphic animation"></div>
                </Card>
                <Card>
                    <div class="square ratio">
                        <div
                            id="phoneWellnessDiscoveryRewards"
                            class="mockup phone expandable"
                            content="/images/siteV2/mockup-init.jpg"
                        ></div>
                    </div>
                </Card>
            </Columns>
            <ImagePreload name="rewards" anims={[anims]} />
            </>
        )
    }
}
Rewards.contextType = PreloadContext
   
export default Rewards