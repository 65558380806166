import React from 'react'
import Pages from './Pages'
import Page from './Page'
import MainHero from './pages/MainHero'
// import HeroTwo from './landings/pages/HeroTwo'
// import RequestDemo from './pages/RequestDemo'
import RequestDemo from './landings/pages/RequestDemo'
import ImagePreload from './ImagePreload'

var MAIN = 'main'

class Home extends React.Component {
	constructor(props) {
		super(props);
        this.state = {}
    }

    render() {

        return (
            <div class="home">

			<Pages
				pagesMenu={true}
				burgerMenu={true}
				submenu={true}
			>

                <MainHero />

                <Page id='thePlatform'
                    title='Platform'
                    inSubmenu={MAIN}
                    handleAppearOnce={()=>{
                        setTimeout(function(){
							turnIn( $('#phoneWelcome') )
							slideIn( $('#phoneHome') )
						},400)
                    }}
                    custom={true}
                >
                    <div class="content">
                        <img
                            class="page-bullet"
                            src="/images/siteV2/bullet-on.png"
                        />
                        <div class="columns-2">
                            <div>
                                <h3>The Platform</h3>
								<div>Our integrated offering lets you tailor a complete wellness program to each office, team, or employee. Live services combined with functional digital modules provide something for everyone. <a class="link section-link" href="request-demo">Request a demo &raquo;</a></div>
                                <div
                                    id="vidPlatform"
                                    class="video ratio"
                                ></div>
                            </div>
                            <div
                                class="threedee-frame match-prev-column-height"
                            >
                                <div
                                    id="phoneHome"
                                    class="mockup phone will-slide-in"
                                    content="/images/siteV2/mockup-home.jpg"
                                    cutbottom="true"
                                    noheader="true"
                                ></div>
                                <div
                                    id="phoneWelcome"
                                    class="mockup phone will-turn-in"
                                    content="/images/siteV2/mockup-welcome.jpg"
                                ></div>
                            </div>
                        </div>
                    </div>
                </Page>

                <Page id='theModules'
                    title='Modules'
                    inSubmenu={MAIN}
                    handleAppearOnce={()=>{
                        setTimeout(function(){
							runAnim(anims, 2)
						},400)
                    }}
                    custom={true}
                >
                    <div
                        class="content"
                    > 
                        <img
                            class="page-bullet"
                            src="/images/siteV2/bullet-on.png"
                        />
                        <h3>The Modules</h3>
                        <div>Deliver valuable, turnkey yet flexible HR solutions: Universal Scheduling, Dynamic Videos, Nutrition Tracking, Rewards &amp; Perks, Insurance, and Reimbursement. New integration? <a class="link section-link" href="#demoRequest">Let's make it work.</a>
                        </div>
                    </div>
                    <div
                        id="featuresDemo"
                        class="content"
                    >
                        <div
                            id="modulesDashedString1"
                            class="dashed-string-horz"
                        ></div>
                        <div
                            id="modulesDashedString2"
                            class="dashed-string-horz"
                        ></div>
                        <div class="columns-2">
                            <div style={{overflow: 'hidden'}}>
                                <div
                                    id="phoneModulesMenu"
                                    class="mockup phone square ratio"
                                    content="/images/siteV2/mockup-init.jpg"
                                    noshadow="true"
                                    shade="true"
                                ></div>
                            </div>
                            <div class="match-prev-column-height">
                                <div
                                    id="phoneModulesOne"
                                    class="mockup phone"
                                    content="/images/siteV2/mockup-init.jpg"
                                ></div>
                                <div
                                    id="phoneModulesTwo"
                                    class="mockup phone"
                                    content="/images/siteV2/mockup-init.jpg"
                                    cutbottom="true"
                                ></div>
                            </div>
                        </div>
                    </div>
                </Page>

                <Page id='theServices'
                    title='Services'
                    inSubmenu={MAIN}
                    handleAppearOnce={()=>{
                        servicesAnimPaused = false
                        setTimeout(initServicesAnim, 0)
                    }}
                    custom={true}
                >
                    <div class="page-block-upper" >
                        <div class="content">
                            <img
                                class="page-bullet"
                                src="/images/siteV2/bullet-on.png"
                            />
                            <div>
                                <h3>The Services</h3>
                                <div>Refresh has over 30 on-site offerings from Nutrition Coaching to Lunch &amp; Learns, Kickboxing, Yoga, and everything in between. Select the perfect combination of services to build your program - we handle everything else. <a class="link section-link" href="service-map">We're in 15 cities in the US and counting!</a></div>
                                <div
                                    id="animServicesRiver"
                                    class="wide ratio"
                                ></div>
                            </div>
                        </div>
                    </div>
                </Page>

                <Page id='ourClients'
                    title='Clients'
                    inSubmenu={MAIN}
                    custom={true}
                >
                    <div class="page-block-upper" >
                        <div class="content">
                            <img
                                class="page-bullet"
                                src="/images/siteV2/bullet-on.png"
                            />
                            <div>
                                <h3>Our Clients</h3>
                                <div>We're proud to serve 100s of clients, including:</div>
                                <div
                                    class="client-logos"
                                >
                                    <img src="/images/siteV2/logo-johnshopkins.png"></img>
                                    <img src="/images/siteV2/logo-reddit.png"></img>
                                    <img src="/images/siteV2/logo-foursquare.png"></img>
                                    <img src="/images/siteV2/logo-mta.png"></img>
                                    <img src="/images/siteV2/logo-silverstein.png"></img>
                                    <img src="/images/siteV2/logo-firstrepublic.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>

                <Page id='serviceMap'
                    title='Where We Are'
                    inSubmenu={MAIN}
                    custom={true}
                >
                    <div class="page-block-upper" >
                        <div class="content">
                            <img
                                class="page-bullet"
                                src="/images/siteV2/bullet-on.png"
                            />
                            <div>
                                <h3>Service Map</h3>
                                <div>Wellness wherever your employees are. Don't see your city? No worries - we can launch services for your office anywhere in the US within three weeks. <a class="link section-link" href="request-demo">Request a demo &raquo;</a></div>
                                <div>
                                    <img class="service-map" src="/images/siteV2/service-map.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>

                <RequestDemo />

            </Pages>

            <ImagePreload images={[
                'iphonex-blue.png',
                'iphonex-blue-noshadow.png',
                
                'mockup-mainmenu.jpg',
                'mockup-cal1.jpg',
                'mockup-cal2.jpg',
                'mockup-book1.jpg',
                'mockup-book2.jpg',
                'mockup-book3.jpg',
                'mockup-book4.jpg',
                'mockup-book5.jpg',
                'mockup-book6.jpg',
                'mockup-playlist1.jpg',
                'mockup-playlist2.jpg',
                'mockup-video1.jpg',
                'mockup-video2.jpg',
                'mockup-challenges1.jpg',
                'mockup-challenges2.jpg',
                'mockup-benefits1.jpg',
                'mockup-rewards1.jpg',
                'mockup-promo1.jpg',
            ]} preload={true} />

            </div>
        )
    }
}

function _wireHome() {
	subMenuStart = 1
	$(function(){

		if(!isLanding)
		{
			subMenuStart -= 1
		}
		initScroll( window.destTriggers )
		postBuild()
	})
}

var A = '#phoneModulesMenu',
	B = '#phoneModulesOne',
	C = '#phoneModulesTwo'

/*
	t: time in # of seconds at which to trigger this block
	mt: time in # of seconds at which to trigger this block on mobile
	p: pic to load
	o: vertical offset (constant)
	d: distance of vertical offset. if set, this block will be a vertical slide animation
*/

var anims = {

	target: A,
	anims: [
		{ 	// Scheduling
			t:0,
			mt:0,
			p:'mockup-mainmenu',
			o: MENU_OFFSET,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-cal1',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-book1'
								},
								{
									t:1.2,
									p:'mockup-book2'
								},
								{
									t:2.2,
									p:'mockup-book3'
								}
							]
						}
					},
					{
						t:3.2,
						p:'mockup-cal2',
						sub: {
							target: C,
							anims: [
								
								{
									t:.2,
									p:'mockup-book4'
								},
								{
									t:1.2,
									p:'mockup-book5'
								},
								{
									t:2.2,
									p:'mockup-book6'
								}
							]
						}
					}
				]
			}
		},
		{ 	
			t:6,
			mt:1.3,
			d:-1,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-init',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-init'
								},
							]
						}
					}
				]
			}
		},
		{ 	// Video
			t:7,
			mt:2.6,
			d:-2,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-playlist1',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-video1'
								},
							]
						}
					},
					{
						t:2.2,
						p:'mockup-playlist2',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-video2'
								}
							]
						}
					}

				]
			}
		},
		{ 	
			t:10,
			mt:3.9,
			d:-3,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-init',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-init'
								},
							]
						}
					}
				]
			}
		},
		{ 	
			t:11,
			mt:5.2,
			d:-4
		},
		{ 	// Challenges
			t:12,
			mt:6.5,
			d:-5,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-challenges1',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-challenges2'
								},
							]
						}
					}
				]
			}
		},
		{ 	
			t:14,
			mt:7.8,
			d:-6,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-init',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-init'
								},
							]
						}
					}
				]
			}
		},
		{ 	// Benefits
			t:15,
			mt:9.1,
			d:-7,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-benefits1'
					}
				]
			}
		},
		{ 	// Rewards
			t:16,
			mt:10.4,
			d:-8,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-rewards1',
						sub: {
							target: C,
							anims: [
								{
									t:.2,
									p:'mockup-init'
								},
							]
						}
					}
				]
			}
		},
		{ 	
			t:17,
			mt:11.7,
			d:-9,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-init'
					}
				]
			}
		},
		{ 	
			t:18,
			mt:13,
			d:-9.75
		},
		{ 	// Promo
			t:19,
			mt:14.3,
			d:-10.75,
			sub: {
				target: B,
				anims: [
					{
						t:.2,
						p:'mockup-promo1'
					}
				]
			}
		}
	]
}

export default Home