import React from 'react'
class CallUs extends React.Component {

    render() {
        return (
            <a
                class="module-callus callout"
                href="#requestDemo"
            >
                Call for a demo 
                <img
                    id="phoneIcon"
                    src="/images/siteV2/phone.png"
                    style={{}}
                />
                800-616-9271
            </a>
        )
    }
}
   
export default CallUs