import React from 'react'
import LandingPage from '../../LandingPage'
import AppPlatformCard from '../../cards/AppPlatform'
import Coordination from '../../cards/Coordination'

function AppPlatform(p) {
    return (
        <LandingPage
            id="universalIntegration"
            title="Universally Integrated"
            {...p}
        >
            <Coordination
                title={<h2>Universally Integrated</h2>}
                body={(
                    <>
                        <p>Refresh is a <b>unified, comprehensive platform</b> providing discovery and access to all <b>benefits, resources, and perks</b> personalized to each employee's <b>position, life phase, or need.</b></p>
                    </>
                )}
                anims={{

                    target: '#phoneIntegration',
                    wait: 0,
                    anims: [
                        {
                            t:1,
                            p:'m-benefits-card'
                        },
                        {
                            t:2,
                            p:'m-cardiogram'
                        },
                        {
                            t:3,
                            p:'m-classlist'
                        },
                        {
                            t:4,
                            p:'m-challenges'
                        },
                        {
                            t:5,
                            p:'m-marathon'
                        },
                        {
                            t:6,
                            p:'m-rebur-list'
                        },
                        // {
                        //     t:7,
                        //     p:'mockup-init'
                        // },
                    ]
                }}
                {...p}
            />
        </LandingPage>
    )
}
   
export default AppPlatform