function changeTag(jParent, fromSel, toTag) {
    let jEls = jParent.find(fromSel)
    jEls.each( (i, el) => {
        let jEl = $(el)
        let text = jEl.text()
        jEl.replaceWith( $(`<${toTag}>${text}</${toTag}>`)  )
    })
}

function clearClasses(jParent, classNames=[]) {
    classNames.map( className =>
        jParent.find(`.${className}`).removeClass(className) )
}

export default {
    changeTag,
    clearClasses
}