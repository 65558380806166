import React from 'react'
import { StyleContext } from '../../../contexts'
import LandingPage from '../../LandingPage'

var hasShown = false

class Hero extends React.Component {

    constructor(props) {
		super(props)
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        let p = this.props
        return (
            <LandingPage
                id="about"
                bulleted={false}
                classes={{
                    'wires-bg': false,
                    'align-top': false,
                    'bulleted': false
                }}
                wideContent={<>
                    {p.showClients &&
                        <div id="landing-clients" class="banner" style={{
                            marginTop:100,
                            backgroundColor:'rgb(235 241 255)',
                            }}>
                            <div
                                class="bg-image landing-clients-img hide-mobile"
                                style={{backgroundImage:"url('/images/siteV2/landing-clients.png')"}}
                            />
                            <div
                                class="bg-image landing-clients-mobile-img hide-desktop"
                                style={{backgroundImage:"url('/images/siteV2/landing-clients-mobile.png')"}}
                            />
                        </div>
                    }
                    </>
                }
                {...p}
            >
                <div class="center">
                    <h2>{p.title}</h2>
                    <div class="testimonial">
                        <img class="headshot hide-mobile" src="/images/siteV2/investment-banks-diagonal.jpg" />
                        <div class="text">
                            <div class="about-quote">
                                From surveying to keeping connected through
                                quarantine, I don't know what we would have
                                done without Refresh in our toolkit.
                            </div>
                            <div class="about-attribution">
                                <div>
                                    <img class="five-stars" src="/images/siteV2/five-stars-dark.png" />
                                </div>
                                <div class="hide-mobile">Capterra Reviewer</div>
                                <div>Investment Banking</div>
                            </div>
                        </div>
                    </div>
                </div>
            </LandingPage>
        )
    }
    
}
Hero.contextType = StyleContext
   
export default Hero