import React from 'react'
import {
    PageContext,
    SectionContext
} from '../contexts'
import registerTriggers from '../Triggers'

class Columns extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        registerTriggers(this)
    }

    render() {
        let p = this.props
        if(!p.children)
            return <div />
        return (
            <PageContext.Consumer>{(pageContext) => (
                <SectionContext.Consumer>{(sectionContext) => (
                    <div 
                        id={p.id}
                        class={`${!p.noSlide ? 'slidable' : ''} ${p.class}`}
                        ref={r=>{
                            this.pageId=pageContext.pageId
                            this.sectionId=sectionContext && sectionContext.sectionId
                        }}
                    >
                        <div class={`columns-${p.children.length} ${!p.noSlide ? 'slidable-content' : ''}`}>
                            {p.children}
                        </div>
                    </div>
                )}</SectionContext.Consumer>
            )}</PageContext.Consumer>
        )
    }
}
   
export default Columns