import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

class DashboardExpenses extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.text = props.text || {
            title: `Expense Reimbursement`,
            body: `Handle the fulfillment workflow for wellness and other expenses. Mark in review, approved, paid, rejected; add notes and comments. Coming soon, pre-process expenses automatically with Amazon Mechanical Turk.`
        }
    }

    render() {
        return (
            <Card class="module-dashboard-expenses">
                <div class="extra-wide ratio">
                    <h4>{this.text.title}</h4>
                    <div>{this.text.body}</div>
                </div>
                <div class="wide ratio">
                    <div
                        id="phoneWorkflowsModulesReimbursement"
                        class="mockup monitor"
                        content="/images/siteV2/m-expenses-list-desktop.jpg"
                    ></div>
                </div>
                <ImagePreload name="dashboard-expenses" images={['m-expenses-list-desktop.jpg']} />
            </Card>
        )
    }
}
   
export default DashboardExpenses