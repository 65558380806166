import React from 'react'
class Screen extends React.Component {

    shouldComponentUpdate() {
        return false
    }

    render() {
        return (
            <div
                id={this.props.id}
                class={`mockup ${this.props.type || 'phone'}`}
                content={`/images/siteV2/${this.props.content}`}
            ></div>
        )
    }
}
   
export default Screen