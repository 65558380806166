import React from 'react'
import LandingPage from '../../LandingPage'
import FormsAndSurveysCard from '../../cards/FormsAndSurveys'

function FormsAndSurveys(p) {
    return (
        <LandingPage
            id="forms-and-surveys"
            title="Forms + Surveys"
            {...p}
        >
            <FormsAndSurveysCard
                title={<>
                    <h2>Smarter Forms + Surveys</h2>
                    <h3>Real-time Insights</h3>
                </>}
                {...p}
            />
        </LandingPage>
    )
}
   
export default FormsAndSurveys