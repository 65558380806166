import React from 'react'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

var anims = {

    target: '#phoneWellnessAmenities',
    wait: 1.5,
    anims: [
        {
            t:1,
            p:'m-classlist'
        },
        {
            t:2.5,
            p:'m-classlist'
        },
        {
            t:4,
            p:'m-yoga'
        },
        {
            t:5.5,
            p:'m-payment'
        },
        {
            t:7,
            p:'m-signed-up'
        },
        {
            t:8.5,
            p:'m-capture'
        },
        {
            t:10,
            p:'m-checked-in'
        },
        {
            t:11.5,
            p:'m-poll'
        },
    ]
}

class AppPlatform extends React.Component {
    
    constructor(props) {
		super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            console.log('AppPlatform componentDidUpdate: this.context.preloaded ', this.context.preloaded)
            this.didInit = true
            let phone = $(this.phone)
            setTimeout(function(){
                turnIn( phone )
            },400)
            runAnim(this.props.anims || anims, 0)
        }
    }

    render() {
        return (
            <Card handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-app-platform">
                {this.props.title || <h4>Digital Engagement</h4>}
                <div>
                    {this.props.body || `Registration and coordination is all handled through the app. We handle payments and co-pays for premium services, and you can even conduct polls for event attendees.`}
                </div>
                <div class="threedee-frame square ratio">
                    <div
                        id="phoneWellnessAmenities"
                        ref={r=>this.phone=r}
                        class="mockup phone will-turn-in expandable"
                        content="/images/siteV2/mockup-init.jpg"
                    ></div>
                </div>
                <ImagePreload name="appplatform" anims={[anims]} />
            </Card>
        )
    }
}
AppPlatform.contextType = PreloadContext
   
export default AppPlatform