import React from 'react'
import Card from '../Card'

function LiveServices(p) {
    return (
        <Card class="live-services">
            {p.title || <h4>Live Sessions</h4>}
            <div>{p.body || `Refresh has over 30 live offerings, from Nutrition Coaching to Lunch & Learns, Kickboxing to Yoga, and everything in between. Select the perfect combination of services to build a wellness program - we handle everything else. Our certified practitioners broadcast live any day of the week, rain or shine.`}</div>
        </Card>
    )
}
   
export default LiveServices