import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

let anims1 = {

    target: '#phoneSurveys',
    wait: 2,
    anims: [
        {
            t:0,
            p:'m-form'
        },
        {
            t:2,
            p:'m-kudos-form'
        },
        {
            t:4,
            p:'m-kudos-people'
        }
    ]
}

class FormsAndSurveys extends React.Component {

    constructor(props) {
		super(props)
        this.state = {}
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(function(){
                turnIn( $('#phoneSurveys') )
                runAnim(anims1, 3)
                delayPeekSlidable( $('#forms-slider') )
            },400)
        }
    }

    render() {
        return (
            <>
            <Columns handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-forms" id="forms-slider">
                <Card>
                    {this.props.title ||
                        <h4>Forms and Surveys</h4>
                    }
                    {this.props.body ||
                        <div>
                            <p>Whether it’s <b>open enrollment</b> or a weekly <b>pulse survey</b>, our automated system can deliver and track a <b>range of response types</b> including <b>yes/no</b>, <b>scale or NPS</b>, or <b>open-ended questions</b>.</p>
                            <p>Employees can also <b>send colleagues "kudos"</b> for a job well done and <b>suggestions for improvements</b> for the company and its policies.</p>
                        </div>
                    }
                    {/* <div id="challengesAnim" class="graphic animation" /> */}
                    <div style={{textAlign:'center'}}>
                        <img id="formsStatic" src="/images/siteV2/forms-static.png" />
                    </div>
                </Card>
                <Card>
                    <div class="threedee-frame square ratio">
                        <div
                            id="phoneSurveys"
                            class="mockup phone will-turn-in expandable"
                            content="/images/siteV2/mockup-init.jpg"
                        ></div>
                    </div>
                </Card>
            </Columns>
            <ImagePreload name="forms" anims={[anims1]} />
            </>
        )
    }
}
FormsAndSurveys.contextType = PreloadContext
   
export default FormsAndSurveys