import React from 'react'
import LandingPage from '../../LandingPage'
import RewardsCard from '../../cards/Rewards'

function Rewards(p) {
    return (
        <LandingPage
            id="discounts-and-perks"
            title="Employee Discounts + Perks"
            {...p}
        >
            <RewardsCard
                title={<h2>Employee Discounts + Perks</h2>}
                {...p}
            />
        </LandingPage>
    )
}
   
export default Rewards