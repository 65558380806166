
function registerTriggers(el) {

    if( !window.destTriggers[el.pageId] )
        window.destTriggers[el.pageId] = {}

    let sectionId = (typeof el.sectionId != 'undefined') ? el.sectionId : 'main'
    
    if( !window.destTriggers[el.pageId][sectionId] )
        window.destTriggers[el.pageId][sectionId] = {
            once: [],
            always: []
        }
    
    let triggers = window.destTriggers[el.pageId][sectionId]
    
    if(el.handleAppearOnce || el.props.handleAppearOnce)
    {
        if(el.handleAppearOnce)
            triggers.once.push( el.handleAppearOnce )
        if(el.props.handleAppearOnce)
            triggers.once.push( el.props.handleAppearOnce )
    }

}
export default registerTriggers