import React from 'react'
import Page from './Page'
class LandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        setTimeout(()=>{
            $(this.page.page).addClass('tilted')
        }, _isInternal ? 0 : 1200)
    }

    render() {
        let p = Object.assign({}, this.props)
        let classes = Object.assign({
            'with-sub-menu': false,
            'no-edge': true,
            'bulleted': true,
            'landing-page': true
        }, p.classes)
        p.classes = classes

        return (
            <Page
                custom={true}
                footer={(
                    <div class="page-block-lower">
                        { p.footerChildren }
                        <div class="content">
                            { line('right bottom') }
                            { !p.isLast &&
                                <div class="landing-advance" onClick={()=>{
                                    $.fn.fullpage.moveSectionDown()
                                }}>
                                    <span class="hide-mobile">{p.idx==1 ? 'Begin' : 'Next:'} </span><b>{p.nextText}</b>
                                    <div class="scroll-button" />
                                </div>
                            }
                        </div>
                    </div>
                )}
                ref={r=>this.page=r}
                {...p}
            >
                <div class="content">
                    { line() }
                    { p.classes.bulleted && !p.classes.dark && bullet() }
                    <div class="landing-content">
                        {this.props.children}
                    </div>
                </div>
                { p.wideContent }
            </Page>
        )
    }
}

var line = cls => (
    <div class={`diagonal-line ${cls || 'left'}`}>
        <div class="dashed-string-vert"></div>
    </div>
    
)

var bullet = cls => (
    <img
        class={`page-bullet ${cls || 'left'}`}
        src="/images/siteV2/bullet-on.png"
    />
)

export default LandingPage