import React from 'react'
import ModularSolution from '../cards/ModularSolution'
import Calendly from '../../Calendly'

function track() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'formSubmissionSuccess',
        formId: 'contactForm'
    })
}

class DemoBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            useCal: true
        }
    }

    onComplete = () => {
        if( pageView )
            pageView(null, '#demoSuccess')
    }

    submit = () => {
        let vals = {},
            errors = 0
        $('.demo-box input').map((idx,el)=>{
            let jEl = $(el), 
                name = jEl.attr('id'),
                val = jEl.val()
            if(     (jEl.hasClass('required') && !val)
                ||  (       name=='email'
                        &&  ( !val.includes('@') || val.includes(' ') )
                    )
            )
            {
                jEl.addClass('error')
                errors++
            }
            else
                jEl.removeClass('error')
            vals[name] = val
        })
        if(errors)
            return

        if(urlParam)
        {
            let utms = ['source', 'campaign']
            utms.map( utm => vals[utm] = urlParam(`utm_${utm}`) )
        }

        this.setState({
            sending: true,
            vals
        })

        fetch('https://msg.app.refreshplatform.com/external-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                dest: 'request-demo',
                vals
            })
        })
            .then(response => {
                console.log(response)
                this.setState({
                    sending: false,
                    done: true
                })
                track()
            })
    }

    render() {
        let demoBox = (
            <>
                <img
                    class="demo-side left hide-mobile"
                    src="/images/siteV2/landing-dialog-side-bg.svg"
                />
                <div class="demo-content">
                    {!this.props.standalone &&
                        <h2 class="button-back" onClick={()=>{
                            this.props.closeFn && this.props.closeFn()
                        }}>
                            ✕
                        </h2>
                    }
                    { !this.state.done ?
                        <div style={ this.state.sending ? { opacity: .5 } : {}}>
                            
                            <div style={{verticalAlign:'top'}}>
                                <div class="half left">
                                    <h2>See It In Action</h2>
                                    <div class="demo-text">
                                        <p class="spacey-text tilted hide-mobile">
                                            {this.state.useCal ?
                                                <>
                                                    <span class="offset-a1"><b>Schedule your own free demo!</b> We'll show you</span>
                                                    <span class="offset-a2">the power of Refresh in 15 minutes or less.</span>
                                                </>
                                                :
                                                <>
                                                    <span class="offset-a1"><b>Thank you for reaching out.</b> We will </span>
                                                    <span class="offset-a2">get back to you right away to answer</span>
                                                    <span class="offset-a3">questions and set up a time for a demo.</span>
                                                </>
                                            }
                                        </p>
                                        <p class="hide-desktop">
                                            {this.state.useCal ?
                                                <>We’re here to give you a free demo! Find a time that works for you:</>
                                                :
                                                <>Just a few questions:</>
                                            }
                                        </p>
                                        <p class="spacey-text tilted hide-mobile">
                                            <span class="offset-b1">
                                                {this.state.useCal ? `Prefer a follow-up?` : `Want to schedule now?`}
                                                <a class="button" onClick={()=>this.setState({ useCal: !this.state.useCal })}>
                                                    {this.state.useCal ? `Contact Us` : `Calendar`} →
                                                </a>
                                            </span>
                                        </p>
                                        <p class="spacey-text tilted hide-mobile">
                                            <span class="offset-c1">
                                                Or call us at: <a href="tel:+18006169271" class="link">800-616-9271</a>
                                            </span>
                                            <span class="offset-c2">(Available 9am-6pm EST)</span>
                                        </p>
                                    </div>
                                    <div class="animation hide-mobile">
                                        <ModularSolution noText={true} autoStart={!this.props.standalone} />
                                    </div>
                                </div>
                                <div class="half right">
                                    {this.state.useCal ?
                                        <Calendly onComplete={this.onComplete} />
                                        :
                                        <div>
                                            { Input('email', 'Email', true) }
                                            { Input('firstName', 'First name', true, 'half left') }
                                            { Input('lastName', 'Last name', true, 'half right') }
                                            { Input('phoneNumber', 'Phone number', true) }
                                            <div class="submit-button" onClick={this.submit} style={ this.state.sending ? { cursor: 'default' } : {} }>
                                                {this.state.sending ? 'Sending...' : 'Submit'}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <h2>{this.state.vals.firstName ? `Thanks ${this.state.vals.firstName}!` : `Thank You` }</h2>
                            <p>We'll get back to you right away at <b>{this.state.vals.email}</b></p>
                            <div class="submit-button" onClick={()=>{
                                this.props.closeFn && this.props.closeFn()
                                this.setState({ done: false })
                            }}>Finish</div>
                        </div>
                    }
                </div>
                <img
                    class="demo-side right hide-mobile"
                    src="/images/siteV2/landing-dialog-side-bg.svg"
                />
            </>
        )
        if(this.props.standalone)
            return (
                <div class="demo-box" style={{position:'relative', display:'inline-block'}}>
                    {demoBox}
                </div>
            )
        else
        {
            if(!this.props.open)
                return <></>
            return (
                <Modal boxClass="demo-box" closeFn={this.props.closeFn}>
                    {demoBox}
                </Modal>
            )
        }
    }
}

var Input = (name, placeholder, required, classes) => (
    <div class={`input-shim ${classes}`}>
        <input id={name} placeholder={placeholder} class={required && 'required'}/>
        <div class="flag refresh-tooltip">
            <span class="refresh-tooltiptext">Required</span>
        </div>
    </div>
)

function Modal(p) {
    return (
        <div class="modal-overlay" onClick={p.closeFn}>
            <div class={`modal-box ${p.boxClass}`} onClick={e=>{ e.stopPropagation() }}>
                {p.children}
            </div>
        </div>
    )
}

export default DemoBox