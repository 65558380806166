import React from 'react'
import LandingPage from '../../LandingPage'
import Coordination from '../../cards/Coordination'

function CommsScheduling(p) {
    return (
        <LandingPage
            id="scheduling"
            title="Scheduling"
            {...p}
        >
            <Coordination
                // title={<h2>{p.title || 'Impressive Scheduling'}</h2>}
                title={p.title || <>
                    <h2>Uncluttered Scheduling</h2>
                    {/* <h3>Relevant and Actionable</h3> */}
                </>}
                // body={(
                //     <p>Put every <b>event</b>, <b>meeting</b>, <b>class</b>, or <b>video session</b> into feeds for easy review and access - <b>sign up</b>, <b>check in</b>, and <b>launch video sessions</b> directly from the scheduler. <b>Tailor schedules</b> to each group so employees only see what's relevant to them. <b>Poll employees</b> through the signup process to collect incremental feedback and data with each interaction. <b>Process payments</b> for events or classes, right in the calendar.</p>
                // )}
                body={(
                    <>
                        <p><b>A better interface</b> for engaging employees with company <b>events, meetings, and classes.</b> </p>

                        {/* <p><b>Tailor schedules</b> to each employee based on relevancy, <b>reducing clutter</b> and confusion.</p>

                        <p>Employees can scroll through each schedule to <b>review options</b>, <b>sign up</b>, and <b>check in</b></p>

                        <p><b>Video sessions</b> can be launched directly without the need to sign into a third party product.</p> 

                        <p><b>Poll employees</b> through the signup process to collect feedback and gather data with each interaction. </p>

                        <p><b>Process payments</b> for any service right in the calendar.</p> */}

                        <div
                            class="feature-bullets"
                            style={{
                                marginTop: 15,
                                marginBottom: 22
                            }}
                        >
                            <div class="schedules"><b>Tailor schedules</b> to each employee based on relevancy, <b>reducing clutter</b> and confusion</div>
                            <div class="checkin">Facilite <b>sign-ups</b> w/ optional capacity limits and <b>verify check-ins</b> with code scanning - can be used for ticketing and entry</div>
                            <div class="live-video"><b>Live video sessions</b> can be launched directly without the need for a third party product. <a href="#wellness-amenities">See: Refresh Live™</a></div>
                            <div class="polls"><b>Poll employees</b> through the signup process to collect feedback and gather data with each interaction</div>
                            <div class="payments"><b>Process payments</b> for any service, right from the calendar</div>
                        </div>
                    </>
                )}
                {...p}
            />
        </LandingPage>
    )
}
   
export default CommsScheduling