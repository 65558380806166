import React from 'react'
import Pages from './Pages'
import DemoBox from './landings/DemoBox'

class LandingPages extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            demoOpen: false
        }
    }

    componentDidMount() {
    }

    render() {
        console.log('LandingPages: re-render')
        return (
            <div class="landing-pages">
                <Pages
                    _opts={{newLanding: true}}
                    {...this.props}
                />
            </div>
        )
    }
    
}

export default LandingPages