import React from 'react'
import Columns from '../Columns'
import Card from '../Card'
import ImagePreload from '../ImagePreload'
import { PreloadContext } from '../../contexts'

let anims = {

    target: '#phoneCoordination',
    wait: 1,
    anims: [
        {
            t:1,
            p:'m-softball'
        },
        {
            t:2,
            p:'m-team-party'
        },
        {
            t:3,
            p:'m-finance'
        },
        {
            t:4,
            p:'m-kickboxing'
        },
        {
            t:5,
            p:'m-excel'
        },
        {
            t:6,
            p:'m-habitat-for-humanity'
        },
        {
            t:7,
            p:'m-marathon'
        },
        {
            t:8,
            p:'m-climbing'
        },
        {
            t:9,
            p:'m-charity'
        },
        
    ]
}

class Coordination extends React.Component {

    constructor(props) {
		super(props)
        this.state = {}
        this.anims = this.props.anims || anims
        this.animId = Math.round(Math.random()*100000)
    }

    componentDidUpdate() {
        if(this.context.preloaded && this.state.visible && !this.didInit)
        {
            this.didInit = true
            setTimeout(()=>{
                turnIn( $(this.anims.target) )
                runAnim(this.anims, 3)
                loadBodyMovin({id: this.animId, file: 'coordination.json'})
                delayPeekSlidable( $('#scheduling-slider') )
            },400)
        }
    }

    render() {
        return (
            <>
            <Columns handleAppearOnce={()=>{ this.setState({visible:true}) }} class="module-coordination" id="scheduling-slider">
                <Card>
                    { this.props.title ||
                        <h4>Universal Scheduling</h4>
                    }
                    { this.props.body ||
                        <div>A single location for marketing and registration across affinity groups, on-site Refresh classes, philanthropy projects, community events, and anything else your company offers employees. Run event ticketing, employee polling, and more through our scheduling tool.</div>
                    }
                    <div id={this.animId} class="graphic animation coordinationGraphic"></div>
                </Card>
                <Card>
                    <div class="threedee-frame square ratio">
                        <div
                            id={this.anims.target.slice(1)}
                            class="mockup phone will-turn-in expandable"
                            content="/images/siteV2/mockup-init.jpg"
                        ></div>
                    </div>
                </Card>
            </Columns>
            <ImagePreload name="coordination" anims={[anims]} />
            </>
        )
    }
}
Coordination.contextType = PreloadContext
   
export default Coordination